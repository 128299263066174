import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { useSelector } from 'react-redux';
import { selectedTheme } from './store/themeModeSlice';
import { SelectedAlert } from './store/AlertStateModeSlice';
import { SelectedProgressbarInRoot } from './store/ProgressbarInRootSlice';
import { useTranslation } from 'react-i18next';
import { Container, createTheme, CssBaseline, ThemeProvider, useTheme } from '@mui/material';
import { enumListSizeDevices } from './config/responsive/configResponsive';
import { ConfigComps } from './config/ConfigCOMP';
import { HelperResponsive } from './helper/Helper';
import { AlertPopup } from './components/MUIConfig/alert_popup/AlertPopup';
import { ProgressBarInRoots } from './components/MUIConfig/progressbar_in_root/ProgressBarInRoot';
import { BrowserRouter, Route, Routes, useParams, useSearchParams, useLocation } from "react-router-dom";
import Links from './config/Links';
import HttpErrorPage from './screens/error/HttpErrorPage';
import { enumTypeOfAlert } from './components/MUIConfig/alert_popup/interfaceAlertPopup';
import ReceiptListPage from './screens/services/receipt/receiptList/ReceiptListPage';
import Test from './screens/test';
import Sidebar from './components/complex/sidebar/Sidebar';
import Colors from './config/Colors';
import ChooseCustomer from './screens/services/receipt/steps/chooseCustomer/ChooseCustomer';
import MyProfile from './screens/myProfile/MyProfile';
import UnderConstruction from './screens/error/underConstruction/UnderConstruction';
import LoginPage from './screens/loginAndRegister/Login/LoginPage';
import { RootState } from './store/store';
import PrivateRedirectToLogin from './api/PrivateRedirectToLogin';
import CustomerProfileParent from './screens/people/TrustyOrCustomer/customers/CustomerProfile/CustomerProfileParent';
import CustomersList from './screens/people/TrustyOrCustomer/customers/customersList/CustomersList';
import TrustysList from './screens/people/TrustyOrCustomer/Trusties/TrustiesList/TrustiesList';
import TrustiesList from './screens/people/TrustyOrCustomer/Trusties/TrustiesList/TrustiesList';
import TrustyProfileParent from './screens/people/TrustyOrCustomer/Trusties/TrustyProfile/TrustyProfileParent';
import CreateTrustyOrCustomer from './screens/people/TrustyOrCustomer/CreateTrustyOrCustomer/CreateTrustyOrCustomer';
import EmployeesList from './screens/people/Employees/EmployeesList/EmployeesList';
import EmployeeProfile from './screens/people/Employees/EmployeeProfile/EmployeeProfile';
import RecapsList from './screens/recap/RecapsList/RecapsList';
import CreateRecap from './screens/recap/CreateRecap/CreateRecap';
import ShowStateTheRecap from './screens/recap/ShowStateTheRecap/ShowStateTheRecap';


function App() {
  const themeInStore = useSelector((state: RootState) => selectedTheme(state));
  const alertStateInStore = useSelector(SelectedAlert);
  const progressbarInstore = useSelector(SelectedProgressbarInRoot);
  const { t, i18n } = useTranslation();
  var confComps = ConfigComps();
  const mTheme = useTheme();
  console.log("safajoooon theme in store", themeInStore);
  const mainThem = createTheme({
    // ...((themeInStore as any)),

    typography: {
      // Set base font size
      htmlFontSize: 1,
      // Define responsive font sizes
      fontSize: 1,
      // You can set responsive font sizes for different breakpoints
    },
    // breakpoints: {
    //   values: {
    //     xs: 0,   // Extra-small devices (portrait phones, less than 600px)
    //     sm: 600, // Small devices (landscape phones, 600px and up)
    //     md: 900, // Medium devices (tablets, 900px and up)
    //     lg: 1200,// Large devices (desktops, 1200px and up)
    //     xl: 1536,// Extra-large devices (large desktops, 1536px and up)
    //   },
    // },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            fontSize: '87.5%', // 14px for xs
            [mTheme.breakpoints.up('xs')]: {
              fontSize: '93.75%', // 15px for sm
            },
            [mTheme.breakpoints.up('sm')]: {
              fontSize: '93.75%', // 15px for sm
            },
            [mTheme.breakpoints.up('md')]: {
              fontSize: '100%', // 16px for md
            },
            [mTheme.breakpoints.up('lg')]: {
              fontSize: '112.5%', // 18px for lg
            },
            [mTheme.breakpoints.up('xl')]: {
              fontSize: '125%', // 20px for xl
            },
          },
        },
      },
    },
  });
  // const [getSearchParamsPage, setSearchParamsPage] = useSearchParams();


  const AddSidebar = (element: any) => {
    var sizeDevice = HelperResponsive();
    return (
      <div style={{
        display: "flex", flexDirection: "row",
        width: "100vw",
      }}>
        <Sidebar typeOfDisplay={sizeDevice} />
        <div style={{
          display: "flex", flexGrow: 1, flexBasis: 0, flexDirection: "row", background: Colors.theme.white.PrimaryLight, maxHeight: "100vh",
        }}>
          <div style={{
            flexDirection: "column",
            display: "flex", flexGrow: "1", flexBasis: "0",
            width: "0",
            borderStartStartRadius: "min(3rem , 4vw)",
            overflow: "hidden",
            borderEndStartRadius: "min(3rem , 4vw)",
            background: "#fff",
            boxShadow: "0px 0px 4px rgba(45,50,80,0.15)",
            zIndex: 0,
            paddingInline: "24px",
            boxSizing: "border-box",
            paddingTop: "calc(1vh + 24px)",
            overflowY: "auto",
          }}>
            {element}
          </div>
        </div>
      </div >
    );

  }
  useEffect(() => {
    // SetDisplayType(HelperResponsive());
  }, []);
  return (
    <ThemeProvider theme={mainThem}>
      {/* <CssBaseline /> */}
      <div dir={mainThem.direction} style={{ direction: mainThem.direction, position: "relative" }}>
        <AlertPopup typeOfWarning={alertStateInStore.typeAlert ? Object.values(enumTypeOfAlert)[Object.values(enumTypeOfAlert).indexOf(alertStateInStore.typeAlert as unknown as enumTypeOfAlert)] : enumTypeOfAlert.error}
          text={alertStateInStore.text} lastTimeecondsUpdate={alertStateInStore.lastTimeecondsUpdate} />
        <ProgressBarInRoots isVisible={progressbarInstore.isVisibility} value={progressbarInstore.value} lastTimeMilisUpdate={progressbarInstore.lastTimeMilisUpdate} />
        <BrowserRouter>
          <Routes>
            {/* login and register */}
            <Route path={Links.screens.loginAndRegister.login} element={<LoginPage />} />
            {/* service */}
            <Route path='/panel' element={<PrivateRedirectToLogin />}>
              {/* recap */}
              <Route path={Links.screens.panel.recap.listRecap} element={AddSidebar(<RecapsList />)} />
              <Route path={Links.screens.panel.recap.addRecap} element={AddSidebar(<CreateRecap />)} />
              <Route path={Links.screens.panel.recap.ShowStateTheRecap + ":idMainRecap/"} element={AddSidebar(<ShowStateTheRecap />)} />
              {/* project */}
              {/* services */}
              <Route path={Links.screens.panel.services.cottage.cottageList} element={AddSidebar(<UnderConstruction />)} />
              <Route path={Links.screens.panel.services.cottage.cottageList} element={AddSidebar(<UnderConstruction />)} />
              <Route path={Links.screens.panel.services.remittance.remittanceList} element={AddSidebar(<UnderConstruction />)} />
              <Route path={Links.screens.panel.services.exchange.exchangeList} element={AddSidebar(<UnderConstruction />)} />

              <Route path={Links.screens.panel.services.receipt.receiptList} element={AddSidebar(<ReceiptListPage />)} />
              <Route path={Links.screens.panel.services.receipt.receiptSteps.chooseCustomer} element={AddSidebar(<ChooseCustomer />)} />
              <Route path={Links.screens.panel.services.receipt.receiptSteps.chooseWalletOfCustomer} element={AddSidebar(<UnderConstruction />)} />
              <Route path={Links.screens.panel.services.receipt.receiptSteps.uploadDeposit} element={AddSidebar(<UnderConstruction />)} />
              <Route path={Links.screens.panel.services.receipt.receiptSteps.uploadWithDraw} element={AddSidebar(<UnderConstruction />)} />
              {/* people */}
              <Route path={Links.screens.panel.people.customers.customersList} element={AddSidebar(<CustomersList />)} />
              <Route path={Links.screens.panel.people.customers.customersProfile + ":idCustomer/"} element={AddSidebar(<CustomerProfileParent />)} />
              <Route path={Links.screens.panel.people.customers.addCustomer + ":role/"} element={AddSidebar(<CreateTrustyOrCustomer />)} />

              <Route path={Links.screens.panel.people.trusties.trustiesList} element={AddSidebar(<TrustiesList />)} />
              <Route path={Links.screens.panel.people.trusties.trustyProfile + ":idTrusty/"} element={AddSidebar(<TrustyProfileParent />)} />
              <Route path={Links.screens.panel.people.trusties.addTrusty + ":role/"} element={AddSidebar(<CreateTrustyOrCustomer />)} />

              <Route path={Links.screens.panel.people.employees.employeeProfile + ":idEmployee/"} element={AddSidebar(<EmployeeProfile />)} />
              <Route path={Links.screens.panel.people.employees.employeesList} element={AddSidebar(<EmployeesList />)} />
              <Route path={Links.screens.panel.people.employees.addEmployee} element={AddSidebar(<EmployeeProfile />)} />

              {/* my Profile */}
              <Route path={Links.screens.panel.myProfile.myProfile} element={AddSidebar(<MyProfile />)} />

              {/* my Setting */}
              <Route path={Links.screens.panel.mySetting.mySetting} element={AddSidebar(<UnderConstruction />)} />

              {/* my Notifications */}
              <Route path={Links.screens.panel.myNotifications.myNotifications} element={AddSidebar(<UnderConstruction />)} />
            </Route>
            <Route path={"/"} element={AddSidebar(<Test />)} />
            <Route path="/404" element={<HttpErrorPage code={404} />} />
            <Route path="*" element={<HttpErrorPage code={404} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
