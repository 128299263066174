import { useEffect, useState } from "react";
import { EnumSortingType, InterfaceItemOfSorting, InterfaceSortingBox } from "./InterfaceSortingBox";
import { ConfigComps } from "../../../config/ConfigCOMP";
import Colors from "../../../config/Colors";
import { SearchIcon } from "../../../assets/icons/svg/SearchIcon";
import { DropdownItemTitle, SortingBoxParent, SortingDropdownBox } from "./SortingBoxStyle";
import { SortIcon } from "../../../assets/icons/svg/SortIcon";
import { BottomTriangleIcon } from "../../../assets/icons/svg/BottomTriangleIcon";
import { AscDownIcon } from "../../../assets/icons/svg/AscDownIcon";
import { DescDownIcon } from "../../../assets/icons/svg/DescDownIcon";
import { CancelRounded, CheckCircleRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import ParentWithWidthAnimation from "../../basic/animation/ParentWithWidthAnimation/ParentWithWidthAnimation";

function SortingBox(componentsParams: InterfaceSortingBox) {
    const [getListItems, setListItems] = useState<InterfaceItemOfSorting[]>(componentsParams.items);
    const [getIsDropdownBoxOpen, setIsDropdownBoxOpen] = useState(false);
    useEffect(() => {

    }, [componentsParams.items])
    var confComps = ConfigComps();
    const handleItemClick = (index: number) => {
        // Create a new array with updated typeOrder for the clicked item
        const updatedList = getListItems.map((item, i) =>
            i === index
                ? {
                    ...item, typeOrder: item.typeOrder === EnumSortingType.none ?
                        EnumSortingType.asc : item.typeOrder === EnumSortingType.asc ?
                            EnumSortingType.desc : EnumSortingType.none
                }
                : item
        );

        // Update the state with the new list
        setListItems(updatedList);
    };
    return (
        <div style={{
            display: "flex", flexDirection: "column", alignItems: "center",
            justifyContent: "space-between",
            flexGrow: "0",
            height: "100%",
            // flexBasis: "",
            position: "relative",
            cursor: getIsDropdownBoxOpen ? undefined : "pointer",
            boxSizing: "border-box",
        }} onClick={() => {
            setIsDropdownBoxOpen(prev => !prev);
        }}>
            <SortingBoxParent
                isDropdownOpen={getIsDropdownBoxOpen}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row", alignItems: "center",
                    justifyContent: "start",
                    flexGrow: "1",
                    position: "relative",
                }}>
                    <SortIcon />
                    <p style={{
                        marginInlineStart: "0.32rem",
                        marginInlineEnd: "0.74rem",
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: "1.28rem",
                        lineHeight: "1.92rem",
                        userSelect: "none",
                    }}>
                        {confComps.component.SortingBox.title}
                    </p>
                    <ParentWithWidthAnimation in={(!getIsDropdownBoxOpen)} timeout={300} style={{ marginInlineEnd: !getIsDropdownBoxOpen ? "-10px" : undefined }}>
                        <KeyboardArrowDownRounded
                            style={{
                                fontSize: "1.9rem",

                                color: Colors.theme.blue.dark,
                                cursor: "pointer",
                                transform: getIsDropdownBoxOpen ? "rotate(180deg)" : "rotate(0deg)",
                                opacity: getIsDropdownBoxOpen ? "0" : "1",
                                transition: "all 0.5s ease-in-out",
                            }}
                        />
                    </ParentWithWidthAnimation>
                    <ParentWithWidthAnimation in={getIsDropdownBoxOpen} timeout={300} style={{ gap: "0.5rem" }}>
                        <CancelRounded
                            style={{
                                color: Colors.theme.orange.sharp,
                                cursor: "pointer",
                            }}
                            onClick={(event) => {
                                setListItems(componentsParams.items)
                                event.stopPropagation();
                                setIsDropdownBoxOpen(false);
                            }}
                        />
                        <CheckCircleRounded
                            style={{
                                color: Colors.theme.green.dark,
                                cursor: "pointer",
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                componentsParams.onclickDone(getListItems);
                                setIsDropdownBoxOpen(false);
                            }}
                        />
                    </ParentWithWidthAnimation>


                </div>

                <SortingDropdownBox isDropdownOpen={getIsDropdownBoxOpen}>
                    {getListItems.map((item, index, arr) => {
                        return (
                            <DropdownItemTitle
                                onClick={() => {
                                    handleItemClick(index)
                                    setIsDropdownBoxOpen(false);
                                }}
                            >
                                {item.title}
                                {(item.typeOrder === EnumSortingType.asc) && <AscDownIcon style={{ color: Colors.theme.blue.blue }} />}
                                {(item.typeOrder === EnumSortingType.desc) && <DescDownIcon style={{ color: Colors.theme.blue.blue }} />}
                            </DropdownItemTitle>
                        )
                    })}

                </SortingDropdownBox>


            </SortingBoxParent>
        </div>
    )
}
export default SortingBox;