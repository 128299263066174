import { useEffect, useState } from "react";
import ParentWithHeightAnimation from "../../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import { InterfaceViewInitiateBuyCottage } from "./InterfaceViewInitiateBuyCottage";
import { Box, Grid, useTheme } from "@mui/material";
import TextFieldSimple from "../../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../../../../NTTs/InterfaceNTTCurrency";
import SelectCurrencyWithTypeAndLocation from "../../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import SubTitleSection from "../../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import TextFieldMultiLine from "../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import Colors from "../../../../../../../config/Colors";
import ButtonSave from "../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import SelectUser from "../../../../../../../components/complex/Selector/SelectUser/SelectUser";
import { InterfaceNTTUser } from "../../../../../../../NTTs/InterfaceNTTUser";
import SelectTrustyOrCustomer from "../../../../../../../components/complex/Selector/SelectTrustyAndCustomer/SelectTrustyOrCustomer";
import { InterfaceGetAPITrustyOrCustomer } from "../../../../../../../components/complex/Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer";
import { InterfaceNTTRecapMain } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { InterfaceSearchInServer } from "../../../../../../../api/interfaces/InterfaceSearchInServer";
import { urlsList, HandlerLangUrl, TypeQueryParams } from "../../../../../../../api/UrlsList";
import { EnumStateFetchList } from "../../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { InterfaceGetAPICustomersList } from "../../../../../../people/TrustyOrCustomer/customers/customersList/InterfaceCustomersList";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Links from "../../../../../../../config/Links";
import KeyTitleH6 from "../../../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import ValueP from "../../../../../../../components/basic/Texts/Titles/ValueP/ValueP";
import TextDescription from "../../../../../../../components/basic/Texts/TextDescription/TextDescription";
import { InterfaceNTTRecapBuyCottageService } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapBuyCottageService";
import dayjs from "dayjs";

function ViewInitiateBuyCottage(paramsComponent: InterfaceViewInitiateBuyCottage) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getInfoInitiateBuyCottage, setInfoInitiateBuyCottage] = useState<InterfaceNTTRecapBuyCottageService>(paramsComponent.infoServiceInMainRecap);

    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();



    useEffect(() => {
        setInfoInitiateBuyCottage(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    return (
        <ParentWithHeightAnimation in={getInfoInitiateBuyCottage !== undefined}
            loading={false}
            style={{
                boxSizing: "border-box",
                width: "100%",
            }}
        >
            <Box sx={{
                boxSizing: "border-box",
                paddingInlineStart: { xs: "0.8rem", sm: "0.9rem", md: "1.0rem", lg: "1.18rem", xl: "1.3rem" },
                paddingInlineEnd: { xs: "0.8rem", sm: "3.33rem", md: "3.7rem", lg: "4.14rem", xl: "6rem" },
                display: "flex", flexDirection: 'column',
                alignItems: 'start',
                width: "100%",
                marginTop: "1.18rem",
            }}>
                <Grid container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 0, sm: 0, md: "4.9rem", lg: "5.40rem", xl: "5.94rem" }}
                    rowSpacing={{ xs: "1.08rem", sm: "1.2rem", md: "1.34rem", lg: "1.48rem", xl: "1.6rem" }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: "0.2rem",
                        }}>
                            <KeyTitleH6 title={confComps.translate.customer} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`${getInfoInitiateBuyCottage?.customer.profile.first_name} ${getInfoInitiateBuyCottage?.customer.profile.last_name}`}
                            />
                            <p style={{
                                color: Colors.theme.blue.Secondary,
                                fontFamily: confComps.font.poppins.SemiBold,
                                fontSize: "0.9rem",
                                lineHeight: "1.6rem",
                                flexWrap: "nowrap",
                                textWrap: "nowrap",
                                cursor: "pointer",
                                userSelect: "none",
                            }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (getInfoInitiateBuyCottage.customer.is_trusty) {
                                        navigate(Links.screens.panel.people.trusties.trustyProfile + `${getInfoInitiateBuyCottage.customer.user.id}/`)
                                    }
                                    else {
                                        navigate(Links.screens.panel.people.customers.customersProfile + `${getInfoInitiateBuyCottage.customer.user.id}/`)
                                    }
                                }}
                            >
                                {confComps.translate.openProfile}
                            </p>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: "0.2rem",
                        }}>
                            <KeyTitleH6 title={confComps.translate.company} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`${getInfoInitiateBuyCottage.company.name}`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: "0.2rem",
                        }}>
                            <KeyTitleH6 title={confComps.translate.amount} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`${getInfoInitiateBuyCottage.amount}`}
                            />
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: "0.2rem",
                        }}>
                            <KeyTitleH6 title={confComps.translate.currency} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`${getInfoInitiateBuyCottage.currency_unit.is_cash ? confComps.translate.cash : confComps.translate.noneCash}`}
                            />
                            <div style={{
                                width: "1px",
                                height: "1rem",
                                boxSizing: "border-box",
                                background: Colors.theme.blue.verticalSeparator,

                            }} />
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`${getInfoInitiateBuyCottage.currency_unit.country.name}`}
                            />
                            <div style={{
                                width: "1px",
                                height: "1rem",
                                boxSizing: "border-box",
                                background: Colors.theme.blue.verticalSeparator,

                            }} />
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`${getInfoInitiateBuyCottage.currency_unit.currency.symbol}`}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: "0.2rem",
                        }}>
                            <KeyTitleH6 title={confComps.translate.expectedCurrencyRate} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`1 AED = ${getInfoInitiateBuyCottage.expected_rate} Rial`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: "0.2rem",
                        }}>
                            <KeyTitleH6 title={confComps.translate.category} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`${getInfoInitiateBuyCottage.category.title}`}
                            />

                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: "0.2rem",
                        }}>
                            <KeyTitleH6 title={confComps.translate.deadline} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`${dayjs.unix(getInfoInitiateBuyCottage.deadline).format("YYYY/MM/DD")}`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: "0.2rem",
                        }}>
                            <KeyTitleH6 title={confComps.translate.haveOrderSubmission} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`${getInfoInitiateBuyCottage.have_order_submission ? "YES" : "NO"}`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: "0.2rem",
                        }}>
                            <KeyTitleH6 title={confComps.translate.haveCurrencyAllocation} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                            <ValueP style={{ color: Colors.theme.blue.dark }}
                                title={`${getInfoInitiateBuyCottage.have_allocation ? "YES" : "NO"}`}
                            />
                        </div>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextDescription>
                            {getInfoInitiateBuyCottage.description}
                        </TextDescription>
                    </Grid>
                </Grid>

            </Box>
        </ParentWithHeightAnimation>
    )

}
export default ViewInitiateBuyCottage;