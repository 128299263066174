import { Box, Grid, useTheme } from "@mui/material";
import Colors from "../../../../../../../config/Colors";
import { InterfaceRowForeignCompany } from "./InterfaceRowForeignCompany";
import KeyTitleH6 from "../../../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import { useDispatch, useSelector } from "react-redux";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import ValueP from "../../../../../../../components/basic/Texts/Titles/ValueP/ValueP";
import { DocumentScannerRounded, DownloadRounded } from "@mui/icons-material";
import { PaperIcon } from "../../../../../../../assets/icons/svg/PaperIcon";
import { DownloadFile, formatDateGregorian, getFileNameFromURL } from "../../../../../../../helper/Helper";
import { EditPenIcon } from "../../../../../../../assets/icons/svg/EditPenIcon";
import { TrashIcon } from "../../../../../../../assets/icons/svg/TrashIcon";
import { HandlerLangUrl, urlsList } from "../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../api/API";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import ButtonArrowWithAnimation from "../../../../../../../components/basic/Buttons/ButtonArrowWithAnimation/ButtonArrowWithAnimation";

function RowForeignCompany(paramsComponent: InterfaceRowForeignCompany) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const callApiForDeleteCompany = async (idCompany: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.company.foreignCompany.deleteForeignCompany;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idCompany}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackReloadPage();
                dispatch(ShowAlert({ text: confComps.translate.removedCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <Box sx={{
            width: "100%", border: `1px solid ${Colors.theme.blue.border}`,
            paddingInline: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
            paddingBlock: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
            boxSizing: "border-box",
            borderRadius: "0.8rem",
        }}>
            <Grid container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 3, xl: 5 }}
                rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 6 }}
            >

                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.companyName} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.name} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.companyCode} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.company_code} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.activity} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }}
                            title={paramsComponent.activities.map((act, index, arr) => {
                                return `${act.title}`
                            }).join(", ")
                            }
                        />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.authenticityDate} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }}
                            title={paramsComponent.authenticity_date ? formatDateGregorian(paramsComponent.authenticity_date) : "--"}
                        />
                    </div>
                </Grid>

                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.country} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }}
                            title={paramsComponent.country}
                        />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.city} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }}
                            title={paramsComponent.city}
                        />
                    </div>
                </Grid>
                {paramsComponent.description && <Grid item xs={6}>
                    <div style={{
                        width: "100%", borderRadius: "0.4rem",
                        background: "#F3F6FC", padding: "0.5rem",
                        boxSizing: "border-box",
                        border: `1px solid ${Colors.theme.blue.light_2}`
                    }}>
                        {paramsComponent.description}

                    </div>
                </Grid>}
                {paramsComponent.docs.map((file, index, arr) => {
                    return (
                        <Grid item xs={4} sm={4} md={4} lg={6} xl={6}>
                            <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "start", gap: "0.5rem" }}>
                                    <KeyTitleH6 title={file.title} />
                                    <PaperIcon />
                                    <KeyTitleH6 title={confComps.translate.description} />
                                    <ValueP title={file.description} />



                                </div>
                                <p style={{
                                    display: "flex", flexDirection: "row", alignItems: "center", color: Colors.theme.green.dark,
                                    cursor: "pointer",
                                }} onClick={() => {

                                    DownloadFile(file.document, file.title);
                                }}>
                                    <DownloadRounded />
                                    {confComps.translate.download}
                                </p>
                            </div>
                        </Grid>
                    )
                })
                }
                <Grid item xs={4} sm={4} md={4} lg={6} xl={6}>
                    <ButtonArrowWithAnimation text={confComps.translate.accounts}
                        callbackClicked={() => {
                            paramsComponent.callbackShowAccounts();
                        }} />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={6} xl={6}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end", width: "100%", gap: "0.5rem" }}>
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                            onClick={paramsComponent.callbackClickOnEdit}
                        >
                            <EditPenIcon style={{
                                background: Colors.theme.blue.light,
                                width: "0.75rem",
                                height: "0.75rem",
                                cursor: "pointer"
                            }}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                            onClick={() => { callApiForDeleteCompany(paramsComponent.id) }}
                        >
                            <TrashIcon />
                        </div>

                    </div>

                </Grid>
            </Grid>


        </Box >
    );
}
export default RowForeignCompany;