import { useEffect, useRef, useState } from "react";
import { InterfaceAdvanceBox, InterfaceAdvanceSearchBox } from "./InterfaceAdvanceSearchBox";
import { ConfigComps } from "../../../config/ConfigCOMP";
import Colors from "../../../config/Colors";
import { SearchIcon } from "../../../assets/icons/svg/SearchIcon";
import { AdvanceBoxParent, AdvanceItemInputOfNumberMoneyType, AdvanceItemInputOfStringType, AdvanceItemOfNumberMoneyType, AdvanceItemTitleOfStringType, SearchBoxParent } from "./AdvanceSearchBoxStyle";
import ParentWithWidthAnimation from "../../basic/animation/ParentWithWidthAnimation/ParentWithWidthAnimation";
import { CancelRounded, CheckCircleRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import DatePickerMUIWithUnderLine from "../../basic/Dates/DatePickerMUIWithUnderLine/DatePickerMUIWithUnderLine";
import DatePickerSimple from "../../basic/Dates/DatePickerSimple/DatePickerSimple";
import { InterfaceNTTRoleEmployee } from "../../../NTTs/InterfaceNTTRoleEmployee";
import TextFieldSelectorRolesEmployeeSimple from "../../basic/TextField/RoleEmployee/TextFieldSelectorRolesEmployeeSimple/TextFieldSelectorRolesEmployeeSimple";
import TextFieldMultiSelectorActivitiesJobSimple from "../../basic/TextField/ActivitiesJob/TextFieldMultiSelectorActivitiesJobSimple/TextFieldMultiSelectorActivitiesJobSimple";
import { ImageSquare } from "../imageSquare/ImageSquare";
export enum EnumItemThatHandleInSmartSearch {
    role = "role",
    activitiesJob = "activities"

}
function AdvanceSearchBox(componentsParams: InterfaceAdvanceSearchBox) {
    const [getSmartSearch, setSmartSearch] = useState<string>(componentsParams.defaultValue.smartSearch ?? "");
    const [getAdvanceParams, setAdvanceParams] = useState<InterfaceAdvanceBox>(componentsParams.defaultValue.advanceParams);
    const [getIsAdvancedBoxOpen, setIsAdvancedBoxOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    var confComps = ConfigComps();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSmartSearch(e.target.value);
    };
    const handleFocus = () => {
        setIsFocused(true);
        // componentsParams.onFocus && componentsParams.onFocus();
    };
    useEffect(() => {
        if (isFocused) {
            const handleClickOutside = (event: MouseEvent) => {
                if (containerRef.current && !containerRef.current.contains(event.target as Node)
                    && getAdvanceParams.dateItem.filter(d => d.isDialogOpenStart === true || d.isDialogOpenEnd === true).length === 0) {
                    setIsFocused(false);
                    setIsAdvancedBoxOpen(false);
                    // componentsParams.onBlur && componentsParams.onBlur();
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [isFocused]);
    return (
        <div
            style={{
                display: "flex", flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                flexGrow: "1",
                flexBasis: "0",
                position: "relative",
                boxSizing: "border-box",

            }}
            ref={containerRef}
        >
            <SearchBoxParent
                isDropdownOpen={getIsAdvancedBoxOpen}
                onClick={handleFocus}
            >
                <div style={{
                    display: "flex", flexDirection: "row", alignItems: "center",
                    justifyContent: "start",
                    flexGrow: "1",
                    gap: getSmartSearch !== (componentsParams.defaultValue.smartSearch ?? "") ? "8px" : undefined,
                    position: "relative",
                }}>
                    <ParentWithWidthAnimation in={getSmartSearch !== (componentsParams.defaultValue.smartSearch ?? "")} timeout={300} style={{ gap: "0.5rem" }}>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsAdvancedBoxOpen(false);
                                componentsParams.doneSearching({ smartSearch: getSmartSearch, advanceParams: getAdvanceParams })
                            }}
                            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                        >
                            <SearchIcon
                                style={{
                                    color: isFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                    minWidth: 17,
                                    cursor: "pointer"
                                }}
                            />
                        </div>
                    </ParentWithWidthAnimation>

                    <input
                        autoComplete="off"
                        style={{
                            color: Colors.theme.blue.dark,
                            flexGrow: "1",
                        }}
                        type="text"
                        value={getSmartSearch}
                        onChange={handleInputChange}
                        placeholder={confComps.component.advanceSearchBox.hintText}
                    />
                </div>

                <ParentWithWidthAnimation in={(!getIsAdvancedBoxOpen)} timeout={300}
                    style={{
                        // background: "red",
                        marginInlineEnd: !getIsAdvancedBoxOpen ? "-4px" : undefined
                    }}
                >
                    <button onClick={() => setIsAdvancedBoxOpen(!getIsAdvancedBoxOpen)}
                        style={{
                            color: getIsAdvancedBoxOpen ? Colors.theme.blue.blue : Colors.theme.blue.light,
                            fontWeight: "500",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                        }}>
                        {confComps.component.advanceSearchBox.buttonAdvance}

                        <KeyboardArrowDownRounded
                            style={{
                                fontSize: "1.9rem",

                                color: Colors.theme.blue.dark,
                                cursor: "pointer",
                                transform: getIsAdvancedBoxOpen ? "rotate(180deg)" : "rotate(0deg)",
                                opacity: getIsAdvancedBoxOpen ? "0" : "1",
                                transition: "all 0.5s ease-in-out",
                            }}
                        />
                    </button>
                </ParentWithWidthAnimation>
                <ParentWithWidthAnimation in={getIsAdvancedBoxOpen} timeout={300} style={{ gap: "0.5rem" }}>
                    <CancelRounded
                        style={{
                            color: Colors.theme.orange.sharp,
                            cursor: "pointer",
                        }}
                        onClick={(event) => {
                            setAdvanceParams(componentsParams.defaultValue.advanceParams);
                            event.stopPropagation();
                            setIsAdvancedBoxOpen(false);
                        }}
                    />
                    <CheckCircleRounded
                        style={{
                            color: Colors.theme.green.dark,
                            cursor: "pointer",
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsAdvancedBoxOpen(false);
                            componentsParams.doneSearching({ smartSearch: getSmartSearch, advanceParams: getAdvanceParams });
                        }}
                    />
                </ParentWithWidthAnimation>


                <AdvanceBoxParent
                    isDropdownOpen={getIsAdvancedBoxOpen}
                >
                    {getAdvanceParams.stringsItem.map((stringItem, index, arr) => {
                        return (
                            <AdvanceItemTitleOfStringType>
                                {stringItem.title}
                                {":"}
                                <AdvanceItemInputOfStringType
                                    type="text"
                                    autoComplete="new-"
                                    placeholder={stringItem.placeholder}
                                    value={stringItem.value}
                                    onChange={(event) => {
                                        let newString = event.target.value;
                                        setAdvanceParams(prev => {
                                            let prevString = prev.stringsItem;
                                            prevString[index].value = newString;
                                            return ({
                                                ...prev,
                                                strings: prevString,
                                            })
                                        })
                                    }}
                                />
                            </AdvanceItemTitleOfStringType>
                        )
                    })}
                    {getAdvanceParams.dateItem.flatMap((dateItem, index, arr) => {
                        return (
                            [<AdvanceItemTitleOfStringType>
                                {`${dateItem.title} ${confComps.translate.from}`}
                                {":"}
                                <DatePickerSimple
                                    title={confComps.translate.dateOfBirth}
                                    onCloseDialogDate={() =>
                                        setAdvanceParams(prev => {
                                            let prevDates = prev.dateItem;
                                            prevDates[index].isDialogOpenStart = false;
                                            return ({
                                                ...prev,
                                                dateItem: prevDates,
                                            })
                                        })
                                    }
                                    onOpenDialogDate={() =>
                                        setAdvanceParams(prev => {
                                            let prevDates = prev.dateItem;
                                            prevDates[index].isDialogOpenStart = true;
                                            return ({
                                                ...prev,
                                                dateItem: prevDates,
                                            })
                                        })
                                    }
                                    onChangeValue={(timeSecond: number | undefined) => {
                                        setAdvanceParams(prev => {
                                            let prevDates = prev.dateItem;
                                            prevDates[index].start = timeSecond;
                                            return ({
                                                ...prev,
                                                dateItem: prevDates,
                                            })
                                        })
                                    }}
                                    defaultTimeInSecond={dateItem.start}
                                />
                            </AdvanceItemTitleOfStringType>
                                ,
                            <AdvanceItemTitleOfStringType>
                                {`${dateItem.title} ${confComps.translate.end}`}
                                {":"}
                                <DatePickerSimple
                                    title={confComps.translate.dateOfBirth}
                                    onCloseDialogDate={() =>
                                        setAdvanceParams(prev => {
                                            let prevDates = prev.dateItem;
                                            prevDates[index].isDialogOpenEnd = false;
                                            return ({
                                                ...prev,
                                                dateItem: prevDates,
                                            })
                                        })
                                    }
                                    onOpenDialogDate={() =>
                                        setAdvanceParams(prev => {
                                            let prevDates = prev.dateItem;
                                            prevDates[index].isDialogOpenEnd = true;
                                            return ({
                                                ...prev,
                                                dateItem: prevDates,
                                            })
                                        })
                                    }
                                    onChangeValue={(timeSecond: number | undefined) => {
                                        setAdvanceParams(prev => {
                                            let prevDates = prev.dateItem;
                                            prevDates[index].end = timeSecond;
                                            return ({
                                                ...prev,
                                                dateItem: prevDates,
                                            })
                                        })
                                    }}
                                    defaultTimeInSecond={dateItem.end}
                                />
                            </AdvanceItemTitleOfStringType>

                            ]
                        )

                    })}
                    {getAdvanceParams.relationIDsItem.map((relationItem, index, arr) => {
                        return (
                            <AdvanceItemTitleOfStringType>
                                {relationItem.title}
                                {":"}
                                {relationItem.itemType === EnumItemThatHandleInSmartSearch.role &&
                                    <TextFieldSelectorRolesEmployeeSimple
                                        styleItems={{
                                            background: Colors.theme.orange.light,
                                            borderRadius: "500px",
                                        }}
                                        hint={confComps.translate.role}
                                        onChangeSelectedRoles={(selectRole: number[]) => {
                                            setAdvanceParams(prev => {
                                                let prevRelation = prev.relationIDsItem;
                                                prevRelation[index].id = selectRole;
                                                return ({
                                                    ...prev,
                                                    relationIDsItem: prevRelation,
                                                })
                                            })
                                        }}
                                        selectedRole={relationItem.id}
                                    // iconEnd={EditPenIcon}
                                    // selectedRole={[]}
                                    />}

                                {relationItem.itemType === EnumItemThatHandleInSmartSearch.activitiesJob &&
                                    <TextFieldMultiSelectorActivitiesJobSimple
                                        styleItems={{
                                            background: Colors.theme.orange.light,
                                            borderRadius: "500px",
                                        }}
                                        hint={confComps.translate.role}
                                        onChangeSelectedRoles={(selectedActivities: number[]) => {
                                            setAdvanceParams(prev => {
                                                let prevRelation = prev.relationIDsItem;
                                                prevRelation[index].id = selectedActivities;
                                                return ({
                                                    ...prev,
                                                    relationIDsItem: prevRelation,
                                                })
                                            })
                                        }}
                                        selectedActivities={relationItem.id}
                                    // iconEnd={EditPenIcon}
                                    // selectedRole={[]}
                                    />
                                }

                            </AdvanceItemTitleOfStringType>
                        );
                    })}
                    {getAdvanceParams.numbersItem.map((numberItem, index, arr) => {
                        return (
                            <AdvanceItemTitleOfStringType>
                                {numberItem.title}
                                {":"}
                                <AdvanceItemOfNumberMoneyType>
                                    <ImageSquare
                                        width={"1.4rem"}
                                        background={Colors.theme.orange.light}
                                        borderRadius="500px"
                                        element={
                                            <p style={{
                                                fontFamily: confComps.font.poppins.Regular,
                                                fontSize: "0.6rem",
                                                color: Colors.theme.blue.dark,
                                            }}>
                                                AED
                                            </p>
                                        }
                                    />
                                    {"Min:"}
                                    <AdvanceItemInputOfNumberMoneyType
                                        type="number"
                                        autoComplete="new-"
                                        placeholder={numberItem.placeholderMin}
                                        value={numberItem.min}
                                        onChange={(event) => {
                                            let newString = event.target.value;
                                            setAdvanceParams(prev => {
                                                let prevNumber = prev.numbersItem;
                                                prevNumber[index].min = newString;
                                                return ({
                                                    ...prev,
                                                    numbersItem: prevNumber,
                                                })
                                            })
                                        }}
                                    />
                                </AdvanceItemOfNumberMoneyType>

                                <AdvanceItemOfNumberMoneyType>
                                <ImageSquare
                                        width={"1.4rem"}
                                        background={Colors.theme.orange.light}
                                        borderRadius="500px"
                                        element={
                                            <p style={{
                                                fontFamily: confComps.font.poppins.Regular,
                                                fontSize: "0.6rem",
                                                color: Colors.theme.blue.dark,
                                            }}>
                                                AED
                                            </p>
                                        }
                                    />
                                    {"Max:"}
                                    <AdvanceItemInputOfNumberMoneyType
                                        type="number"
                                        autoComplete="new-"
                                        placeholder={numberItem.placeholderMax}
                                        value={numberItem.max}
                                        onChange={(event) => {
                                            let newString = event.target.value;
                                            setAdvanceParams(prev => {
                                                let prevNumber = prev.numbersItem;
                                                prevNumber[index].max = newString;
                                                return ({
                                                    ...prev,

                                                })
                                            })
                                        }}
                                    />
                                </AdvanceItemOfNumberMoneyType>
                            </AdvanceItemTitleOfStringType>
                        )
                    })}
                </AdvanceBoxParent>
            </SearchBoxParent >
        </div>
    )
}
export default AdvanceSearchBox;