import { EnumSTepsParent, EnumSTepsRecap, EnumSTepsSolutionRecap } from "../../../../config/enums/EnumSteps";
import { EnumRecapServiceNameInServer } from "../InterfaceNTTWorkFlowMainRecap";

export interface InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap {
    id: number;
    recap: number;
    recap_service_ct?: EnumRecapServiceNameInServer;
    recap_service_id?: number;
    previous_review_ct?: EnumRecapServiceStepNameInServerForMoneyReceive | null;
    previous_review_id?: number | null;
    review_ct: EnumRecapServiceStepNameInServerForMoneyReceive;
    review_id: number;
    created_at: number;

}
export enum EnumRecapServiceStepNameInServerForMoneyReceive {
    undefined = "undefined",
    fundControlCheckWalletCustomer = "recapservicefundcontrol",
    checkRisk = "recapservicecompliance",
    addSolution = "recapservicesolutionmeta",
    checkRiskOfSolution = "recapservicesolutionriskmeta",
    checkWalletOfTrustByFinance = "recapservicesolutionfinancecheckmeta",
    checkSolutionByTraders = "recapservicesolutiontradercheckmeta",
    chooseOneSolution = "recapservicesolutionchoose",
    approveSolution = "recapserviceapproval",
}
export const StepNameMappingToKeyStepForMoneyReceiveRecap: { [key in EnumRecapServiceStepNameInServerForMoneyReceive]: string } = {
    [EnumRecapServiceStepNameInServerForMoneyReceive.undefined]: `0`,
    [EnumRecapServiceStepNameInServerForMoneyReceive.fundControlCheckWalletCustomer]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.checkWalletOfTheRequesterByFundControl}`,
    [EnumRecapServiceStepNameInServerForMoneyReceive.checkRisk]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}`,
    [EnumRecapServiceStepNameInServerForMoneyReceive.addSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.addSolution}`,
    [EnumRecapServiceStepNameInServerForMoneyReceive.checkRiskOfSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}`,
    [EnumRecapServiceStepNameInServerForMoneyReceive.checkWalletOfTrustByFinance]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionsByFinance}`,
    [EnumRecapServiceStepNameInServerForMoneyReceive.checkSolutionByTraders]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionsWithTrusties}`,
    [EnumRecapServiceStepNameInServerForMoneyReceive.chooseOneSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.chooseSolution}`,
    [EnumRecapServiceStepNameInServerForMoneyReceive.approveSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.acceptByManager}`,
};