import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import Colors from "../../../../config/Colors";
import { keyframes, styled, useTheme } from "@mui/material";
import { SyncRounded } from "@mui/icons-material";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { RequestHandler } from "../../../../api/API";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../MUIConfig/alert_popup/interfaceAlertPopup";
import LineSeparator from "../../../basic/LineSeprator/LineSeparator";
import { InterfaceNTTCity } from "../../../../NTTs/InterfaceNTTCity";
import { InterfaceSelectUser } from "./InterfaceSelectUser";
import { InterfaceNTTUser, InterfaceUserWithRole } from "../../../../NTTs/InterfaceNTTUser";
import { BottomTriangleIcon } from "../../../../assets/icons/svg/BottomTriangleIcon";
import { InterfaceNTTProfile } from "../../../../NTTs/InterfaceNTTProfile";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    fontSize: "1.12rem",
    lineHeight: "1.68rem",
    width: "100%",
    paddingInline: "1rem",
    paddingBlock: "0.32rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
const TagOfUser = styled("p")(({ theme }) => ({
    boxSizing: "border-box",
    fontSize: "1.12rem",
    lineHeight: "1.6rem",
    marginInlineStart: "0.5rem",
    color: Colors.theme.orange.light,

}));
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#fff",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
function SelectUser(paramsComponent: InterfaceSelectUser) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getResponseAllUsers, setResponseAllUsers] = useState<{
        user: InterfaceUserWithRole,
        profile: InterfaceNTTProfile
    }[]>([]);
    const [getSelectedUser, setSelectedUser] = useState<{
        user: InterfaceUserWithRole,
        profile: InterfaceNTTProfile
    }>();
    const [getSearchText, setSearchText] = useState<string>(paramsComponent.defaultUser?.profile.last_name ?? "");
    const [getStateIsSearching, setStateIsSearching] = useState<boolean>(false);
    const [getIsFocused, setIsFocused] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const containerRef2 = useRef<HTMLDivElement>(null);


    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("call changed", event.target.value)
        // Clear the previous timeout if it exists
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // Get the current value from the input
        const { value } = event.target;
        // Set a new timeout
        const newTimeoutId = setTimeout(() => {
            // Call your API function here with the updated value
            callApiForGetListAllUsers(value);
        }, 1000); // Adjust the delay time as needed (e.g., 1000ms = 1 second)
        // Update state to store the timeout ID
        setTimeoutId(newTimeoutId);
        // Update the input value state
        setSearchText(value);
    };




    const callApiForGetListAllUsers = async (searchText: string) => {
        dispatch(isVisibilityProgressBar(true));
        setStateIsSearching(true);
        let urlObj = urlsList.panel.user.getAllUser;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme, { search: searchText }),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response user:):)");
                console.log(response);
                let t = response.data as ({
                    user: InterfaceUserWithRole,
                    profile: InterfaceNTTProfile
                }[]);
                setResponseAllUsers(t);

                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
        setStateIsSearching(false);


    }
    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside2 = (event: MouseEvent) => {
                if (containerRef2.current && !containerRef2.current.contains(event.target as Node)) {
                    setIsFocused(false);
                    setSearchText(getSelectedUser?.user.username ?? "");
                }
            };

            document.addEventListener("mousedown", handleClickOutside2);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside2);
            };
        }
    }, [getIsFocused]);
    useEffect(() => {
        callApiForGetListAllUsers("");
    }, [])
    useEffect(() => {
        getSelectedUser && paramsComponent.onChangeSelectedUser(getSelectedUser);
    }, [getSelectedUser])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', position: "relative" }}
            ref={containerRef2}
        >
            <label style={{
                fontWeight: "300",
                fontFamily: confComps.font.poppins.Regular,
                fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                marginBottom: '0.22rem'
            }}>
                {paramsComponent.title}
            </label>
            <div
                style={{
                    background: Colors.theme.white.white90,
                    border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                    boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                    borderRadius: '0.4rem',
                    boxSizing: "border-box",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    overflow: "hidden",
                    justifyContent: "space-between",
                }}
                onClick={() => setIsFocused(true)}
            >
                {getSelectedUser && !getIsFocused &&
                    <div>
                        <RowOfList onClick={() => {
                            setSearchText("");
                            setIsFocused(false);
                        }}>

                            {(getSelectedUser.profile.first_name.length === 0) ? "??" : getSelectedUser.profile.first_name}
                            {" "}

                            {getSelectedUser.profile.last_name}
                            <div style={{
                                width: "1px",
                                height: "1rem",
                                marginInline: "0.5rem",
                                boxSizing: "border-box",
                                background: Colors.theme.blue.verticalSeparator,

                            }} />
                            {getSelectedUser.user.is_customer &&
                                <TagOfUser style={{ color: Colors.theme.orange.light }}>
                                    {confComps.translate.customer}
                                </TagOfUser>
                            }
                            {getSelectedUser.user.is_employee &&
                                <TagOfUser style={{ color: Colors.theme.blue.Secondary }}>
                                    {confComps.translate.employee}
                                </TagOfUser>
                            }
                            {getSelectedUser.user.is_trusty &&
                                <TagOfUser style={{ color: Colors.theme.blue.Secondary }}>
                                    {confComps.translate.trusty}
                                </TagOfUser>
                            }
                        </RowOfList>
                    </div>
                }
                {(getIsFocused || (getSelectedUser === undefined)) &&
                    <input
                        autoComplete="off"
                        type="text"
                        style={{
                            width: "100%",
                            paddingInline: '0.64rem',
                            paddingBlock: '0.32rem',
                            boxSizing: "border-box",
                            fontFamily: confComps.font.poppins.Regular,
                            fontSize: '1.12rem',
                            color: Colors.theme.blue.dark,
                            outline: 'none',
                            lineHeight: "1.68rem",
                        }}
                        value={getSearchText}
                        onChange={handleChangeInput}

                    />
                }
                <BottomTriangleIcon style={{
                    transform: getIsFocused ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.5s ease-in-out",
                    marginInlineEnd: "0.5rem",
                }} />
            </div>
            {

                <DropdownBox
                    isDropdownOpen={getIsFocused}
                >
                    {getStateIsSearching &&
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                            <SpinnerIcon />
                        </div>
                    }
                    {!getStateIsSearching && (getResponseAllUsers.length === 0) &&
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                            {confComps.translate.notFound}
                        </div>
                    }
                    {getResponseAllUsers.flatMap((user, index, arr) => {
                        if ((index + 1) === arr.length) {
                            return (
                                [<RowOfList onClick={() => {
                                    setSearchText("");
                                    setSelectedUser(user);
                                    setIsFocused(false);
                                }}>
                                    <input
                                        autoComplete="off"
                                        type="radio" checked={user.user.id === (getSelectedUser?.user.id ?? -1)} style={{
                                            margin: 0,
                                            boxSizing: "border-box", marginInlineEnd: "0.5rem",
                                        }}
                                    />
                                    {(user.profile.first_name.length === 0) ? "??" : user.profile.first_name}
                                    {" "}
                                    {user.profile.last_name}
                                    <div style={{
                                        width: "1px",
                                        height: "1rem",
                                        marginInline: "0.5rem",
                                        boxSizing: "border-box",
                                        background: Colors.theme.blue.verticalSeparator,

                                    }} />
                                    {user.user.is_customer &&
                                        <TagOfUser style={{ color: Colors.theme.orange.light }}>
                                            {confComps.translate.customer}
                                        </TagOfUser>
                                    }
                                    {user.user.is_employee &&
                                        <TagOfUser style={{ color: Colors.theme.blue.Secondary }}>
                                            {confComps.translate.employee}
                                        </TagOfUser>
                                    }
                                    {user.user.is_trusty &&
                                        <TagOfUser style={{ color: Colors.theme.blue.Secondary }}>
                                            {confComps.translate.trusty}
                                        </TagOfUser>
                                    }
                                </RowOfList>
                                ]
                            )
                        }
                        return (
                            [<RowOfList onClick={() => {
                                setSearchText("");
                                setSelectedUser(user);
                                setIsFocused(false);
                            }}>
                                <input
                                    autoComplete="off"
                                    type="radio" checked={user.user.id === (getSelectedUser?.user.id ?? -1)} style={{
                                        margin: 0,
                                        boxSizing: "border-box", marginInlineEnd: "0.5rem",
                                    }} />
                                {(user.profile.first_name.length === 0) ? "??" : user.profile.first_name}
                                {" "}
                                {user.profile.last_name}
                                <div style={{
                                    width: "1px",
                                    height: "1rem",
                                    marginInline: "0.5rem",
                                    boxSizing: "border-box",
                                    background: Colors.theme.blue.verticalSeparator,

                                }} />
                                {user.user.is_customer &&
                                    <TagOfUser style={{ color: Colors.theme.orange.light }}>
                                        {confComps.translate.customer}
                                    </TagOfUser>
                                }
                                {user.user.is_employee &&
                                    <TagOfUser style={{ color: Colors.theme.blue.Secondary }}>
                                        {confComps.translate.employee}
                                    </TagOfUser>
                                }
                                {user.user.is_trusty &&
                                    <TagOfUser style={{ color: Colors.theme.blue.Secondary }}>
                                        {confComps.translate.trusty}
                                    </TagOfUser>
                                }
                            </RowOfList>
                                ,
                            <LineSeparator />
                            ]

                        );
                    })
                    }
                </DropdownBox>


            }
        </div >

    );
}
export default SelectUser;