import { useEffect, useRef, useState } from "react";
import { InterfaceParentWithHeightAnimation } from "./InterfaceParentWithHeightAnimation";

function ParentWithHeightAnimation(paramsComponent: InterfaceParentWithHeightAnimation) {
    const [height, setHeight] = useState('auto');
    const [opacity, setOpacity] = useState(0);
    const [shouldRender, setShouldRender] = useState(paramsComponent.in);
    const [overflow, setOverflow] = useState(paramsComponent.in ? 'visible' : 'hidden'); // State to manage overflow style
    const contentRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateHeight = () => {
            if (contentRef.current && paramsComponent.in) {
                const scrollHeight = contentRef.current.scrollHeight;
                setHeight(`${scrollHeight}px`);
            }
        };

        if (paramsComponent.in) {
            setShouldRender(true);
            setOverflow('hidden'); // Hide overflow during the animation
            updateHeight();
            setOpacity(1);
            setTimeout(() => {
                setOverflow('visible'); // Make overflow visible after the animation
            }, paramsComponent.timeout);
        } else {
            setHeight('0px');
            setOpacity(0);
            setOverflow('hidden'); // Hide overflow during the animation
            setTimeout(() => {
                setShouldRender(false);
                setOverflow('hidden'); // Make overflow visible after the animation completes
            }, paramsComponent.timeout);
        }

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (childRef.current) {
            resizeObserver.observe(childRef.current);
        }

        return () => {
            if (childRef.current) {
                resizeObserver.unobserve(childRef.current);
            }
        };
    }, [paramsComponent.in, paramsComponent.timeout]);

    return (
        <div
            ref={contentRef}
            style={{
                overflow: overflow, // Apply the overflow style conditionally
                opacity: opacity,
                height: height,
                boxSizing: "border-box",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                transition: `all ${paramsComponent.timeout}ms ease-in-out`,
                ...paramsComponent.style,
            }}
        >
            {paramsComponent.loading && (
                <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}>
                    {/* Simple loading spinner */}
                    <div style={{
                        border: "4px solid rgba(0, 0, 0, 0.2)",
                        borderRadius: "50%",
                        borderTop: "4px solid #000",
                        width: "30px",
                        height: "30px",
                        animation: "spin 1s linear infinite"
                    }} />
                    <style>{`
                        @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                        }
                    `}</style>
                </div>
            )}
            {paramsComponent.loading && (
                <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}>
                    {/* Simple loading spinner */}
                    <div style={{
                        border: "4px solid rgba(0, 0, 0, 0.2)",
                        borderRadius: "50%",
                        borderTop: "4px solid #000",
                        width: "30px",
                        height: "30px",
                        animation: "spin 1s linear infinite"
                    }} />
                    <style>{`
                        @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                        }
                    `}</style>
                </div>
            )}

            <div
                ref={childRef}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "start",
                    opacity: paramsComponent.loading ? 0.5 : 1,
                    ...paramsComponent.style,
                }}
            >
                {paramsComponent.children}
            </div>
        </div>
    );
}

export default ParentWithHeightAnimation;
