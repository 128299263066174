import { profile } from "console";
import { EnumSectionsSidebar } from "./enums/EnumSectionsSidebar";

const Links = {
    screens: {
        loginAndRegister: {
            login: "/login/",
        },
        panel: {
            recap:{
                listRecap:`/panel/${EnumSectionsSidebar.recap}/recaps/`,
                addRecap:`/panel/${EnumSectionsSidebar.recap}/recaps/add/`,
                ShowStateTheRecap:`/panel/${EnumSectionsSidebar.recap}/recaps/recap_steps/`,
            },
            services: {
                cottage: {
                    cottageList: `/panel/${EnumSectionsSidebar.services}/cottage/`,
                },
                remittance: {
                    remittanceList: `/panel/${EnumSectionsSidebar.services}/remittance/`,
                },
                exchange: {
                    exchangeList: `/panel/${EnumSectionsSidebar.services}/exchange/`,
                },
                receipt: {
                    receiptList: `/panel/${EnumSectionsSidebar.services}/receipt/`,
                    receiptSteps: {
                        chooseCustomer: `/panel/${EnumSectionsSidebar.services}/receipt/steps/chooseCustomer`,
                        chooseWalletOfCustomer: `/panel/${EnumSectionsSidebar.services}/receipt/steps/chooseWallet`,
                        uploadWithDraw: `/panel/${EnumSectionsSidebar.services}/receipt/steps/uploadWithDraw`,
                        uploadDeposit: `/panel/${EnumSectionsSidebar.services}/receipt/steps/uploadDeposit`,
                    }
                }

            },
            people: {
                customers: {
                    customersList: `/panel/${EnumSectionsSidebar.people}/customers/customers_list/`,
                    customersProfile: `/panel/${EnumSectionsSidebar.people}/customers/customers_list/profile/`,
                    addCustomer: `/panel/${EnumSectionsSidebar.people}/customers/customers_list/add/`,

                },
                trusties: {
                    trustiesList: `/panel/${EnumSectionsSidebar.people}/trusties/trusties_list/`,
                    trustyProfile: `/panel/${EnumSectionsSidebar.people}/trusties/trusties_list/profile/`,
                    addTrusty: `/panel/${EnumSectionsSidebar.people}/trusties/trusties_list/add/`,
                },
                employees: {
                    employeesList: `/panel/${EnumSectionsSidebar.people}/employees/employees_list/`,
                    employeeProfile: `/panel/${EnumSectionsSidebar.people}/employees/employees_list/profile/`,
                    addEmployee: `/panel/${EnumSectionsSidebar.people}/employees/employees_list/add/`,
                },

            },
            myProfile: {
                myProfile: `/panel/${EnumSectionsSidebar.myProfile}/`,
            },
            mySetting: {
                mySetting: `/panel/${EnumSectionsSidebar.mySetting}/`,
            },
            myNotifications: {
                myNotifications: `/panel/${EnumSectionsSidebar.myNotification}/`,
            }

        }

    },
}
export default Links;