import { useNavigate } from "react-router-dom";
import ContainerPage from "../../components/basic/containerPage/ContainerPage";
import { ImageSquare } from "../../components/complex/imageSquare/ImageSquare";
import Colors, { ColorThemForButton } from "../../config/Colors";
import { ConfigComps } from "../../config/ConfigCOMP";
import { InterfaceAPICallGetMyProfileInfo, InterfaceMyProfile } from "./InterfaceMyProfile";
import { DivImageProfile, MainCard } from "./MyProfileStyle";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { isVisibilityProgressBar } from "../../store/ProgressbarInRootSlice";
import { baseUrl, HandlerLangUrl, urlsList } from "../../api/UrlsList";
import { RequestHandler } from "../../api/API";
import { ShowAlert } from "../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import Links from "../../config/Links";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../api/APIErrorResponse";
import { CancelRounded, DoneRounded, EditOutlined, EditRoad, EditRounded, Person2Outlined, Person2Rounded } from "@mui/icons-material";
import { EditPenCircle } from "../../assets/icons/svg/EditPenCircleIcon";
import { PinLocationIcon } from "../../assets/icons/svg/PinLocationIcon";
import { SelectedProfile } from "../../store/profileStateSlice";
import LineSeparator from "../../components/basic/LineSeprator/LineSeparator";
import { GridFromMUI } from "../../components/MUIConfig/GridFromMUI";
import TextFieldWithIconBottomLine from "../../components/basic/TextField/TextFieldWithIconBottomLine/TextFieldWithIconBottomLine";
import { EditPenIcon } from "../../assets/icons/svg/EditPenIcon";
import { formatDateGregorian } from "../../helper/Helper";
import ButtonSave from "../../components/basic/Buttons/ButtonSave/ButtonSave";
import TextFieldSimple from "../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import ButtonColor from "../../components/basic/Buttons/ButtonColor/ButtonColor";
import ParentWithHeightAnimation from "../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import FilesDragAndDrop from "../../components/complex/FileDragAndDrop/FileDragAndDrop";

function MyProfile(paramsPage: InterfaceMyProfile) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getEditTextUser, setEditTextUser] = useState<string>("");
    const [getEditTextPassword, setEditTextPassword] = useState<string>("");
    const [getResponseMyProfile, setResponseMyProfile] = useState<InterfaceAPICallGetMyProfileInfo>();
    const [getAddress, setAddress] = useState<string>("");
    const [getOldPassword, setOldPassword] = useState<string>("");
    const [getNewPassword, setNewPassword] = useState<string>("");
    const [getReNewPassword, setReNewPassword] = useState<string>("");
    const [getIsPasswordShow, setIsPasswordShow] = useState<boolean>(false);
    const [getFileForImageProfile, setFileForImageProfile] = useState<{ file?: File, urlServer?: string }>();


    const callAPIForGetMyProfile = async () => {
        dispatch(isVisibilityProgressBar(true));
        var urlSelected = urlsList.panel.myProfile.getInformationOfMyProfile;

        await RequestHandler(dispatch, HandlerLangUrl(urlSelected.url, mTheme),
            urlSelected.method, urlSelected.isTokenNecessary, undefined, profileInStore, false)
            .then((response: any) => {
                console.log("response:)");
                console.log(response);
                let t = response as InterfaceAPICallGetMyProfileInfo;
                setResponseMyProfile(t);
                setAddress(t.profile.address);
                setFileForImageProfile({ file: undefined, urlServer: `${baseUrl}${t.profile.profile_pic}` })
                // dispatch(ShowAlert({
                //     text: "fetched data.",
                //     typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds()
                // }));
                navigate(Links.screens.panel.myProfile.myProfile);
                dispatch(isVisibilityProgressBar(false));

            })
            .catch((e: any) => {
                console.log("response:(");
                console.error(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `2لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForUploadImageOfProfile = async () => {
        if (!getFileForImageProfile?.file) {
            dispatch(isVisibilityProgressBar(false));
            return;
        }
        dispatch(isVisibilityProgressBar(true));


        let urlObj = urlsList.panel.myProfile.postUploadProfilePicture;

        const formData = new FormData();
        formData.append("profile_pic", getFileForImageProfile.file);

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, formData, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdEmployee, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callAPIForUpdateMyProfile = async () => {
        dispatch(isVisibilityProgressBar(true));
        var urlSelected = urlsList.panel.myProfile.updateInformationOfMyProfile;
        var obj = {
            "address": getAddress,
        }

        await RequestHandler(dispatch, HandlerLangUrl(urlSelected.url, mTheme),
            urlSelected.method, urlSelected.isTokenNecessary, obj, profileInStore, false)
            .then((response: any) => {
                console.log("response:)");
                console.log(response);
                let t = response as InterfaceAPICallGetMyProfileInfo;
                setResponseMyProfile(t);
                setAddress(t.profile.address);
                setFileForImageProfile({ file: undefined, urlServer: `${baseUrl}${t.profile.profile_pic}` })
                callApiForUploadImageOfProfile();
                dispatch(ShowAlert({
                    text: confComps.translate.yourProfileUpdated,
                    typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds()
                }));
                navigate(Links.screens.panel.myProfile.myProfile);
                dispatch(isVisibilityProgressBar(false));

            })
            .catch((e: any) => {
                console.log("response:(");
                console.error(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `2لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callAPIForUpdateMyPassword = async () => {
        if (getNewPassword !== getReNewPassword) {
            dispatch(ShowAlert({
                text: confComps.translate.yourConfirmPasswordNotMatchWithPassword,
                typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds()
            }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        var urlSelected = urlsList.panel.myProfile.changeMyPassword;
        var obj = {
            "old_password": getOldPassword,
            "new_password": getNewPassword,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlSelected.url, mTheme),
            urlSelected.method, urlSelected.isTokenNecessary, obj, profileInStore, false)
            .then((response: any) => {
                console.log("response:)");
                console.log(response);
                setIsPasswordShow(false);
                // let t = response as InterfaceAPICallGetMyProfileInfo;
                // setResponseMyProfile(t);
                // setAddress(t.profile.address);
                dispatch(ShowAlert({
                    text: confComps.translate.yourPasswordUpdated,
                    typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds()
                }));
                navigate(Links.screens.panel.myProfile.myProfile);
                dispatch(isVisibilityProgressBar(false));

            })
            .catch((e: any) => {
                console.log("response:(");
                console.error(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `2لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        callAPIForGetMyProfile();
    }, []);
    useEffect(() => {
        if (getFileForImageProfile && getFileForImageProfile.file) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setFileForImageProfile(prev => {
                    return ({
                        ...prev,
                        urlServer: event.target.result as string
                    })
                });
            };

            reader.readAsDataURL(getFileForImageProfile.file);
        }
    }, [getFileForImageProfile]);
    return (
        <ContainerPage title={confComps.panel.myProfile.pageInfo.title}>
            <MainCard>
                <Box sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "column", md: "row", lg: "row", xl: "row" },
                    justifyContent: "start",
                    alignItems: { xs: "center", sm: "center", md: "stretch", lg: "stretch", xl: "stretch" },
                    boxSizing: "border-box",
                    gap: { xs: "0.8rem", sm: "1.3rem", md: "2rem", lg: "2.22rem", xl: "2.42rem" },//"2.56rem",
                    marginBottom: "1.77rem",
                }}>
                    <DivImageProfile>
                        <FilesDragAndDrop
                            onUpload={(files) => {
                                files.forEach((value) => {
                                    setFileForImageProfile({ file: value });
                                })
                            }}
                            count={1}
                            formats={['jpg', 'png']}
                            containerStyles={{
                                borderRadius: "4px",
                                boxSizing: "border-box",

                            }}
                            openDialogOnClick
                        >
                            <ImageSquare width={"100%"}
                                borderRadius="500px"
                                style={{ position: "relative", overflow: "visible", boxSizing: "border-box" }}
                                iconSvg={getFileForImageProfile?.urlServer ? undefined : Person2Rounded}
                                img={getFileForImageProfile?.urlServer}
                                element={


                                    <EditPenCircle style={{
                                        zIndex: 5,
                                        borderRadius: "500px",
                                        background: "#fff",
                                        position: "absolute",
                                        width: "1.4rem",
                                        height: "1.4rem",
                                        transform: "translate(50%,50%)",
                                        bottom: "14.64466094%", right: "14.64466094%",

                                    }}
                                    />

                                    // </div>

                                }
                                elementStyle={{
                                    zIndex: 4,
                                    position: "absolute",
                                    width: "28px",
                                    transform: "translate(0,0)",
                                    top: "100px", left: "100%",
                                }}
                                border={`2px solid ${Colors.theme.blue.blue}`}
                                color={Colors.theme.blue.light}
                                background={Colors.theme.white.PrimaryLight}
                            />
                        </FilesDragAndDrop>

                    </DivImageProfile>
                    <div style={{
                        display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between",
                        flexGrow: "1",
                        paddingBlock: "1.18rem",

                    }}>
                        <p style={{
                            color: Colors.theme.blue.dark, fontSize: "1.48rem", lineHeight: "2.22rem",
                            fontFamily: confComps.font.poppins.Medium,
                            fontWeight: "200"
                        }}>
                            {getResponseMyProfile?.profile.first_name}
                            {" "}
                            {getResponseMyProfile?.profile.last_name}
                        </p>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "0.32rem" }}>
                            <PinLocationIcon />
                            <p style={{
                                color: Colors.theme.blue.light, fontSize: "1.18rem", lineHeight: "1.77rem",
                                fontFamily: confComps.font.poppins.Regular,
                            }}>
                                {((getResponseMyProfile?.profile.address ?? "").length < 1) ? "No address added!" : getResponseMyProfile?.profile.address}
                            </p>
                        </div>
                    </div>
                    <div style={{
                        display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between",
                        flexGrow: "0.5",
                        paddingBlock: "1.28rem",

                    }}>
                        <div style={{
                            background: Colors.theme.blue.white,
                            paddingInline: "1.40rem",
                            paddingBlock: "0.14rem",
                            boxSizing: "border-box",
                            color: Colors.theme.blue.blue,
                            fontFamily: confComps.font.poppins.Regular,
                            borderRadius: "10rem",
                            fontSize: "1.18rem",
                            lineHeight: "1.77rem",
                        }}>
                            {getResponseMyProfile?.role.title}

                        </div>
                    </div>
                </Box>
                <LineSeparator Style={{ marginBottom: "1.2rem" }} />

                <h5 style={{
                    fontFamily: confComps.font.poppins.Medium,
                    fontSize: "1.28rem",
                    lineHeight: "1.92rem",
                    color: Colors.theme.blue.dark,
                    fontWeight: "100",
                    marginBlock: "0px",
                    marginBottom: "2.4rem",
                }}>
                    {confComps.panel.myProfile.pageInfo.fields.header}
                </h5>
                <Box sx={{
                    marginInlineStart: { xs: 1, sm: 3, md: 3, lg: 3, xl: 4 },
                    marginInlineEnd: { xs: 1, sm: 6, md: 10, lg: 25, xl: 30 },
                    boxSizing: "border-box",
                }}>
                    <GridFromMUI container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 0, sm: 0, md: "8.81rem", lg: "14.81rem", xl: "9vw" }}
                        rowSpacing={{ xs: "1.2rem", sm: "1.39rem", md: "1.53rem", lg: "1.70rem", xl: "1.87rem" }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                    >

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.panel.myProfile.pageInfo.fields.name.hint}
                                onChangeValue={(value: string) => {

                                }}
                                isLockValue={true}
                                defaultValue={getResponseMyProfile?.profile.first_name}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.panel.myProfile.pageInfo.fields.family.hint}
                                onChangeValue={(value: string) => {

                                }}
                                isLockValue={true}
                                defaultValue={getResponseMyProfile?.profile.last_name}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.panel.myProfile.pageInfo.fields.nationalID.hint}
                                onChangeValue={(value: string) => {

                                }}
                                isLockValue={true}
                                defaultValue={getResponseMyProfile?.profile.national_id}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.panel.myProfile.pageInfo.fields.phone.hint}
                                onChangeValue={(value: string) => {

                                }}
                                isLockValue={true}
                                defaultValue={getResponseMyProfile?.profile.phone}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.panel.myProfile.pageInfo.fields.dateOfBirth.hint}
                                onChangeValue={(value: string) => {

                                }}
                                isLockValue={true}
                                defaultValue={getResponseMyProfile?.profile.dob ? formatDateGregorian(getResponseMyProfile?.profile.dob) : ""}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.translate.DateJoined}
                                onChangeValue={(value: string) => {

                                }}
                                isLockValue={true}
                                defaultValue={getResponseMyProfile?.user.date_joined ? formatDateGregorian(getResponseMyProfile?.user.date_joined) : ""}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.panel.myProfile.pageInfo.fields.address.hint}
                                onChangeValue={(value: string) => {
                                    setAddress(value);
                                }}
                                defaultValue={getAddress}
                                iconEnd={EditPenIcon}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.panel.myProfile.pageInfo.fields.email.hint}
                                onChangeValue={(value: string) => {

                                }}
                                isLockValue={true}
                                defaultValue={getResponseMyProfile?.user.email}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                            />
                        </Grid>
                    </GridFromMUI>
                </Box>

                {<ParentWithHeightAnimation in={getIsPasswordShow} timeout={1300}
                >
                    <MainCard style={{ marginTop: "2rem", paddingInline: "0rem" }}>
                        <div style={{
                            display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "stretch",
                            boxSizing: "border-box",
                            gap: "2.56rem",
                            marginBottom: "0.52rem",
                        }}>
                            <Box sx={{
                                marginInlineStart: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                                marginInlineEnd: { xs: 2, sm: 6, md: 10, lg: 32, xl: 50 },
                                boxSizing: "border-box",
                            }}>
                                <Grid container
                                    justifyContent="start" alignItems="flex-start"
                                    columnSpacing={{ xs: 2, sm: 15, md: 10, lg: 48, xl: 55 }}
                                    rowSpacing={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 6 }}
                                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                                >

                                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                                        <TextFieldSimple title={confComps.translate.oldPassword} textValue={getOldPassword}
                                            onChangeValue={setOldPassword}
                                            type="password"
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                                        <TextFieldSimple title={confComps.translate.newPassword} textValue={getNewPassword}
                                            onChangeValue={setNewPassword}
                                            type="password"
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                                        <TextFieldSimple title={confComps.translate.confirmNewPassword} textValue={getReNewPassword}
                                            onChangeValue={setReNewPassword}
                                            type="password"
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "end", alignItems: "center", gap: "1rem" }}>
                                            <ButtonColor text={confComps.translate.cancel} iconStart={<CancelRounded />} color={ColorThemForButton.orangeColorAndTransparentBackground}
                                                onclick={() => setIsPasswordShow(false)} />
                                            <ButtonColor text={confComps.translate.submit} iconStart={<DoneRounded />} color={ColorThemForButton.greenColorAndTransparentBackground}
                                                onclick={() => callAPIForUpdateMyPassword()} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </MainCard>
                </ParentWithHeightAnimation>
                }

                <div style={{
                    display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: getIsPasswordShow ? "end" : "space-between", width: "100%",
                    marginTop: "6rem",
                }}>
                    {!getIsPasswordShow &&
                        <ButtonColor styleMainDiv={{
                            fontFamily: confComps.font.poppins.Regular,
                            fontSize: "1.037rem",
                            lineHeight: "1.55rem",
                            paddingBlock: "0.29rem",
                            paddingInline: "1.18rem",
                            boxSizing: "border-box",
                            cursor: "pointer",
                            borderRadius: "0.59rem",
                        }}
                            color={ColorThemForButton.LightBlueColorAndTransparentBackground}
                            onclick={() => setIsPasswordShow(prev => !prev)}
                            text={confComps.panel.myProfile.pageInfo.changePassword.buttonText}
                        />
                    }
                    <ButtonSave text={confComps.translate.update}
                        callbackOnClick={() => {
                            callAPIForUpdateMyProfile();
                        }}
                    />
                </div>



            </MainCard>


        </ContainerPage>
    )
}
export default MyProfile;