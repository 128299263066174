import { useNavigate, useParams } from "react-router-dom";
import ContainerPage from "../../../../../components/basic/containerPage/ContainerPage";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { InterfaceTrustyProfileParent, InterfaceGetAPITheTrustyInfo } from "./InterfaceTrustyProfileParent";
import { useDispatch, useSelector } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../api/API";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ImageSquare } from "../../../../../components/complex/imageSquare/ImageSquare";
import { Person2Rounded } from "@mui/icons-material";
import Colors from "../../../../../config/Colors";
import { EditPenCircle } from "../../../../../assets/icons/svg/EditPenCircleIcon";
import { PinLocationIcon } from "../../../../../assets/icons/svg/PinLocationIcon";
import ArrayText from "../../../../../components/basic/Texts/ArrayText/ArrayText";
import { getColorCodeOfBalanceNumber } from "../../../../../helper/Helper";
import TrustyProfileHeader from "./TrustyProfileHeader/TrustyProfileHeader";
import { MainCard } from "./TrustyProfileParentStyle";
import NavbarHorizontal from "../../../../../components/complex/NavbarHorizontal/NavbarHorizontal";
import AllRecommender from "../../SubInfoProfile/Recommendation/AllRecommender/AllRecommender";
import AllRecommended from "../../SubInfoProfile/Recommendation/AllRecommended/AllRecommended";
import CashAccounts from "../../SubInfoProfile/CashAccounts/CashAccounts";
import DomesticCompany from "../../SubInfoProfile/Companies/DomesticCompany/DomesticCompany";
import ForeignCompany from "../../SubInfoProfile/Companies/ForeignCompany/ForeignCompany";
import CustomerBasicInformation from "../../SubInfoProfile/CustomerBasicInformation/CustomerBasicInformation";
import AllChangeRate from "../../SubInfoProfile/rate/ChangeRate/ChangeRate/AllChangeRate";
import AllReceptionRate from "../../SubInfoProfile/rate/ReceptionRate/AllReceptionRate/AllReceptionRate";
import AllNestaWallets from "../../SubInfoProfile/NestaWallets/AllNestaWallets/AllNestaWallets";

export enum EnumSubPageProfileTrustyId {
    profileSection = 1,
    cashAccountSection,
    nestaWalletSection,
    rate,
    changeRateSection,
    receptionRateSection,
    recommendation,
    recommendedSection,
    recommenderSection,
    dealSection,
    serviceSection,
    company,
    foreignCompanySection,
    domesticCompanySection,
}
function TrustyProfileParent(paramsPage: InterfaceTrustyProfileParent) {
    var confComps = ConfigComps();
    const { idTrusty } = useParams();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getSelectedSubPage, setSelectedSubPage] = useState<EnumSubPageProfileTrustyId>();
    const [getResponseTrustyInfo, setResponseTrustyInfo] = useState<InterfaceGetAPITheTrustyInfo>();
    const getApiTheTrustyInfo = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.trusty.getTheTrustyInfo;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTrusty}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceGetAPITheTrustyInfo;
                setResponseTrustyInfo(t);

                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        getApiTheTrustyInfo();
    }, [])
    return (
        <ContainerPage title={confComps.translate.trustyProfile}>
            <MainCard>
                <TrustyProfileHeader userInfo={getResponseTrustyInfo}
                    callbackSelectWallet={() => {
                        setSelectedSubPage(EnumSubPageProfileTrustyId.nestaWalletSection);
                    }}
                    isWalletSelected={getSelectedSubPage === EnumSubPageProfileTrustyId.nestaWalletSection}
                />
                <NavbarHorizontal
                    titleList={confComps.panel.people.trusties.profile.pageInfo.navbarHorizontalItem}
                    callbackOnClick={(id: number) => {
                        setSelectedSubPage(id);
                    }} isUnderLineShow={getSelectedSubPage !== EnumSubPageProfileTrustyId.nestaWalletSection}
                />
                <Box sx={{
                    paddingInline: { xs: 2, sm: 4, md: 5, lg: 6, xl: 6 },
                    paddingBlock: { xs: 1, sm: 2, md: 3, lg: 4, xl: 4 },
                    boxSizing: "border-box",
                }}>
                    {(getSelectedSubPage === EnumSubPageProfileTrustyId.nestaWalletSection) && getResponseTrustyInfo && <AllNestaWallets userInfoBasic={getResponseTrustyInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileTrustyId.receptionRateSection) && getResponseTrustyInfo && <AllReceptionRate userInfoBasic={getResponseTrustyInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileTrustyId.changeRateSection) && getResponseTrustyInfo && <AllChangeRate userInfoBasic={getResponseTrustyInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileTrustyId.profileSection) && getResponseTrustyInfo &&
                        <CustomerBasicInformation
                            userInfoBasic={getResponseTrustyInfo}
                            callbackReload={getApiTheTrustyInfo}
                        />
                    }
                    {(getSelectedSubPage === EnumSubPageProfileTrustyId.recommenderSection) && getResponseTrustyInfo && <AllRecommender userInfoBasic={getResponseTrustyInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileTrustyId.recommendedSection) && getResponseTrustyInfo && <AllRecommended userInfoBasic={getResponseTrustyInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileTrustyId.cashAccountSection) && getResponseTrustyInfo && <CashAccounts userInfoBasic={getResponseTrustyInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileTrustyId.domesticCompanySection) && getResponseTrustyInfo && <DomesticCompany userInfoBasic={getResponseTrustyInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileTrustyId.foreignCompanySection) && getResponseTrustyInfo && <ForeignCompany userInfoBasic={getResponseTrustyInfo} />}
                </Box>
            </MainCard>

        </ContainerPage>
    );
}
export default TrustyProfileParent;