import { useTheme } from "@mui/material";
import { InterfaceParentOfOneSolution } from "./InterfaceParentOfOneSolution";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../../../../config/ConfigCOMP";
import { InterfaceNTTRecapMain } from "../../../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { InterfaceNTTRecapMoneyReceiveService } from "../../../../../../../../NTTs/Recap/InterfaceNTTRecapMoneyReceiveService";
import { InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap } from "../../../../../../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap";
import { ShowAlert } from "../../../../../../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../../../../store/ProgressbarInRootSlice";
import { InterfaceAPIResponseGetMetaDataAddSolutions, InterfaceAPIResponseGetOneSolution } from "../../ViewAddSolutionForMoneyReceive/InterfaceViewAddSolutionForMoneyReceive";
import { InterfaceNTTWorkflowSolution } from "../../../../../../../../NTTs/Recap/solution/InterfaceNTTWorkflowSolution";
import CreateRiskMeasurementSolution from "./RiskMeasurementSolution/CreateRiskMeasurementSolution/CreateRiskMeasurementSolution";
import { EnumSTepsParent, EnumSTepsRecap, EnumSTepsSolutionRecap } from "../../../../../../../../config/enums/EnumSteps";

function ParentOfOneSolution(paramsComponent: InterfaceParentOfOneSolution) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getInfoServiceMoneyReceive, setInfoServiceMoneyReceive] = useState<InterfaceNTTRecapMoneyReceiveService>(paramsComponent.infoServiceInMainRecap);
    const [getInfoThisWorkFlow, setInfoThisWorkFlow] = useState<InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap>();
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>('1');
    const [getInfoThisSolution, setInfoThisSolution] = useState<InterfaceAPIResponseGetOneSolution>(paramsComponent.infoSolution);
    const [getAllWorkflowReviews, setAllWorkflowReviews] = useState<InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap[]>([]);
    const [getWorkFlowOfSolution, setWorkFlowOfSolution] = useState<InterfaceNTTWorkflowSolution[]>([]);
    const [getClickInButtonOfParentSubmit, setClickInButtonOfParentSubmit] = useState<{ itemClicked: string; time: number; }>({ itemClicked: "", time: -1 });
    const callApiForRetrieveWorkflowOfThisSolution = async (params: InterfaceAPIResponseGetOneSolution) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyReceive.steps.addSolutions.stepSolution.getWorkFlowSolution;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${params.id}/workflow-reviews/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceNTTWorkflowSolution[];
                setWorkFlowOfSolution(t);


                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (paramsComponent.infoSolution) {
            console.log("safajoon key in final:", paramsComponent.stepThatSelected);
            callApiForRetrieveWorkflowOfThisSolution(paramsComponent.infoSolution);
            setInfoThisSolution(paramsComponent.infoSolution);
        }
    }, [paramsComponent.infoSolution])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setClickInButtonOfParentSubmit(paramsComponent.clickOnButtonInParent)
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setAllWorkflowReviews(paramsComponent.allWorkflowReviews);
    }, [paramsComponent.allWorkflowReviews])
    useEffect(() => {
        setInfoThisWorkFlow(paramsComponent.StepWorkFlow);
    }, [paramsComponent.StepWorkFlow])
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setInfoServiceMoneyReceive(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    return (
        <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'start',
            justifyContent: 'start',
            width: '100%',
        }}>

            {getWorkFlowOfSolution.length === 0 &&//not added this step
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}` &&
                //selected step is risk solution
                <CreateRiskMeasurementSolution
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceMoneyReceive}
                    allWorkflowReviews={getAllWorkflowReviews}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    clickOnButtonInParent={getClickInButtonOfParentSubmit}
                />
            }

        </div>
    )
};
export default ParentOfOneSolution;