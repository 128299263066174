// import * as React from 'react';
// import { useAutocomplete, AutocompleteGetTagProps } from '@mui/base/useAutocomplete';
// import CheckIcon from '@mui/icons-material/Check';
// import CloseIcon from '@mui/icons-material/Close';
// import { styled } from '@mui/material/styles';
// import { autocompleteClasses } from '@mui/material/Autocomplete';
// import { useState } from 'react';
// import Colors from '../../../config/Colors';
// import { InterfaceAutoCompleteWithUnderLine } from './InterfaceAutoCompleteWithUnderLine';
// import { InterfaceItemOfAutoComplete } from '../AutoCompleteSimple/InterfaceAutoCompleteSimple';

// const Root = styled('div')(
//   ({ theme }) => `

//   color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
//     };
//   font-size: 14px;
// `,
// );

// const Label = styled('label')`
//   padding: 0 0 4px;
//   line-height: 1.5;
//   display: block;
//   color: ${Colors.theme.blue.light};
//   &.focused {
//     color: ${Colors.theme.blue.blue};
//   }
//   marginBottom: '0.22rem';
// `;

// const InputWrapper = styled('div')(
//   ({ theme }) => `
//   width: 100%;
//   box-sizing: border-box;
//   border-radius: 4px;
//   padding: 1px;
//   display: flex;
//   flex-wrap: wrap;

//   boxShadow: undefined;
//   &:hover {

//     boxShadow:${Colors.shadow.shadowEditTextBoxClicked};
//   }

//   &.focused {

//     boxShadow:${Colors.shadow.shadowEditTextBoxClicked};
//   }

//   & input {
//     height: 30px;
//     box-sizing: border-box;
//     padding: 4px 6px;
//     width: 0;
//     min-width: 30px;
//     flex-grow: 1;
//     border: 0;
//     margin: 0;
//     outline: 0;
//   }
// `,
// );

// interface TagProps extends ReturnType<AutocompleteGetTagProps> {
//   label: string;
// }

// function Tag(props: TagProps) {
//   const { label, onDelete, ...other } = props;
//   return (
//     <div {...other}>
//       <span>{label}</span>
//       <CloseIcon onClick={onDelete} />
//     </div>
//   );
// }

// const StyledTag = styled(Tag)<TagProps>(
//   ({ theme }) => `
//   display: flex;
//   align-items: center;
//   height: 24px;
//   margin: 2px;
//   line-height: 22px;
//   background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'};

//   border-radius: 2px;
//   box-sizing: content-box;
//   padding: 0 4px 0 10px;
//   outline: 0;
//   overflow: hidden;



//   & span {
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//   }

//   & svg {
//     font-size: 12px;
//     cursor: pointer;
//     padding: 4px;
//   }
// `,
// );

// const Listbox = styled('ul')(
//   ({ theme }) => `
//   width: 100%;
//   margin: 2px 0 0;
//   padding: 0;
//   position: absolute;
//   list-style: none;
//   background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
//   overflow: auto;
//   max-height: 250px;
//   border-radius: 4px;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//   z-index: 1;

//   & li {
//     padding: 5px 12px;
//     display: flex;

//     & span {
//       flex-grow: 1;
//     }

//     & svg {
//       color: transparent;
//     }
//   }

//   & li[aria-selected='true'] {
//     background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
//     font-weight: 600;

//     & svg {
//       color: #1890ff;
//     }
//   }

//   & li.${autocompleteClasses.focused} {
//     background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
//     cursor: pointer;

//     & svg {
//       color: currentColor;
//     }
//   }
// `,
// );

// export default function AutoCompleteWithUnderLine(componentsParams: InterfaceAutoCompleteWithUnderLine) {
//   const [getIsFocused, setIsFocused] = useState(false);

//   const {
//     getRootProps,
//     getInputLabelProps,
//     getInputProps,
//     getTagProps,
//     getListboxProps,
//     getOptionProps,
//     groupedOptions,
//     value,
//     focused,
//     setAnchorEl,
//   } = useAutocomplete({
//     defaultValue: componentsParams.selectedValueTitle ?? [],
//     multiple: true,
//     options: componentsParams.valuesList,
//     getOptionLabel: (option) => option.title,
//   });
//   React.useEffect(() => {
//     componentsParams.onChangeSelectedItem(value);
//   }, [value])
//   React.useEffect(() => {

//   },[componentsParams.selectedValueTitle])
//   React.useEffect(() => {
//     if (focused) {
//       componentsParams.onFocus && componentsParams.onFocus();
//     }
//     else {
//       componentsParams.onBlur && componentsParams.onBlur();
//     }
//   }, [focused])

//   // React.useEffect(() => {

//   // }, [componentsParams])
//   return (
//     <Root style={{ position: "relative", minWidth: "290px" }}>
//       <div {...getRootProps()}>

//         <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''} sx={{

//           width: "100%",
//         }}>
//           {value.map((option: InterfaceItemOfAutoComplete, index: number) => {
//             const { key, ...tagProps } = getTagProps({ index });
//             return <StyledTag key={key} {...tagProps} label={option.title} />;
//           })}
//           <input {...getInputProps()}
//           // onFocus={() => { setIsFocused(true) && getInputProps().onFocus }}
//           // onBlur={() => {
//           //   setIsFocused(false);
//           //   if (getInputProps().onBlur()) {
//           //     getInputProps().onBlur();
//           //   }
//           // }}
//           />
//         </InputWrapper>
//       </div>
//       {groupedOptions.length > 0 ? (
//         <Listbox {...getListboxProps()}>
//           {(groupedOptions as InterfaceItemOfAutoComplete[]).map((option, index) => {
//             const { ...optionProps } = getOptionProps({ option, index });
//             return (
//               <li  {...optionProps}>
//                 <span>{option.title}</span>
//                 <CheckIcon fontSize="small" />
//               </li>
//             );
//           })}
//         </Listbox>
//       ) : null}
//     </Root>
//   );
// }

// // interface FilmOptionType {
// //     title: string;
// //     year: number;
// // }

import React, { useState, useEffect, useRef } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Colors from '../../../config/Colors';
import { InterfaceAutoCompleteWithUnderLine } from './InterfaceAutoCompleteWithUnderLine';
import { InterfaceItemOfAutoComplete } from '../AutoCompleteSimple/InterfaceAutoCompleteSimple';
import { ConfigComps } from '../../../config/ConfigCOMP';
import { CancelRounded } from '@mui/icons-material';

const AutoCompleteWithUnderLine = (componentsParams: InterfaceAutoCompleteWithUnderLine) => {
  var confComps = ConfigComps();
  const [isFocused, setIsFocused] = useState(false);
  const [selectedValues, setSelectedValues] = useState<InterfaceItemOfAutoComplete[]>(componentsParams.selectedValueTitle ?? []);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<InterfaceItemOfAutoComplete[]>(componentsParams.valuesList);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // if (componentsParams.selectedValueTitle) {
    //   const newSelectedValues = componentsParams.valuesList.filter((item) =>
    //     componentsParams.selectedValueTitle.includes(item.title)
    //   );
    setSelectedValues(componentsParams.selectedValueTitle);
    // }
  }, [componentsParams.selectedValueTitle, componentsParams.valuesList]);

  useEffect(() => {
    setFilteredOptions(
      componentsParams.valuesList.filter((item) =>
        item.title.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  }, [inputValue, componentsParams.valuesList]);

  const handleFocus = () => {
    setIsFocused(true);
    componentsParams.onFocus && componentsParams.onFocus();
  };

  // const handleBlur = () => {
  //   setIsFocused(false);
  //   componentsParams.onBlur && componentsParams.onBlur();
  // };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOptionClick = (option: InterfaceItemOfAutoComplete) => {
    console.log("clicked", option);
    if (!selectedValues.some((item) => item.title === option.title)) {
      const newSelectedValues = [...selectedValues, option];
      setSelectedValues(newSelectedValues);
      componentsParams.onChangeSelectedItem(newSelectedValues);
    }
    else {
      const newSelectedValues = selectedValues.filter(selected => selected.title !== option.title);
      setSelectedValues(newSelectedValues);
      componentsParams.onChangeSelectedItem(newSelectedValues);
    }
    setInputValue('');
  };
  useEffect(() => {
    if (isFocused) {
      const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
          setIsFocused(false);
          componentsParams.onBlur && componentsParams.onBlur();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isFocused]);

  const handleTagRemove = (option: InterfaceItemOfAutoComplete) => {
    const newSelectedValues = selectedValues.filter((item) => item.title !== option.title);
    setSelectedValues(newSelectedValues);
    componentsParams.onChangeSelectedItem(newSelectedValues);
  };

  return (
    <div style={{ position: 'relative', minWidth: '290px',
     }}
      ref={containerRef}
    >
      <div style={{
        width: '100%', boxSizing: 'border-box',
        borderRadius: '4px', padding: '1px', display: 'flex',
        flexDirection: "row", flexWrap: 'wrap',
      }}
        onClick={() => inputRef.current?.focus()}>
        {selectedValues.map((option, index) => (
          <div key={index} style={{
            display: 'flex', alignItems: 'center', height: '24px', margin: '2px', lineHeight: '22px', backgroundColor: '#fafafa',
            borderRadius: '2px', boxSizing: 'content-box', padding: '0 4px 0 10px', outline: 0, overflow: 'hidden',
            gap: "0.4rem",
            paddingBlock: "0.24rem",
            ...componentsParams.styleItems,
          }}
          >
            <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {option.title}
            </span>
            <CancelRounded
              style={{
                color: Colors.theme.orange.sharp,
                cursor: "pointer",
              }}
              onClick={(event) => {
                handleTagRemove(option)
                event.stopPropagation();
              }}
            />
          </div>
        ))}
        <input
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}

          style={{
            height: '30px', boxSizing: 'border-box', padding: '4px 6px', width: 0, minWidth: '30px',
            flexGrow: 1, border: 0, margin: 0, outline: 0
          }}
        />
      </div>
      {isFocused && filteredOptions.length > 0 && (
        <ul
          style={{
            width: '100%', margin: '2px 0 0', padding: 0,
            top: "20",
            position: 'absolute', listStyle: 'none', backgroundColor: '#fff', overflow: 'auto',
            borderRadius: '4px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', zIndex: 1
          }}
        >
          {filteredOptions.map((option, index) => (
            <li
              onClick={() => handleOptionClick(option)}
              style={{
                padding: '5px 12px',
                display: 'flex',
                cursor: 'pointer',
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: selectedValues.some((item) => item.title === option.title) ? '#fafafa' : '#fff',
                fontFamily: selectedValues.some((item) => item.title === option.title) ? confComps.font.poppins.Medium : confComps.font.poppins.Regular,
                //  fontWeight: selectedValues.some((item) => item.title === option.title) ? 300 : 200,
                fontSize: "0.9rem",
              }}
            >
              <span>{option.title}</span>
              {selectedValues.some((item) => item.title === option.title) && <CheckIcon fontSize="small" />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutoCompleteWithUnderLine;
