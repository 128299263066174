import { useNavigate } from "react-router-dom";
import Colors from "../../../config/Colors";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { InterfaceOpenProfile } from "./InterfaceOpenProfile";
import Links from "../../../config/Links";

function OpenProfile(paramsComponent: InterfaceOpenProfile) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    return (
        <div
            style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "start", gap: "0.3rem"
            }}
        >
            <p style={{
                color: Colors.theme.blue.dark,
                fontFamily: confComps.font.poppins.Regular,
                fontSize: "1rem",
                lineHeight: "1.5rem",
                flexWrap: "nowrap",
                textWrap: "nowrap",
                userSelect: "none",
            }}
            >
                {`${paramsComponent.profile.first_name} ${paramsComponent.profile.last_name}`}
            </p>
            <p style={{
                color: Colors.theme.blue.Secondary,
                fontFamily: confComps.font.poppins.SemiBold,
                fontSize: "0.9rem",
                lineHeight: "1.6rem",
                flexWrap: "nowrap",
                textWrap: "nowrap",
                cursor: "pointer",
                userSelect: "none",
            }}
                onClick={(event) => {
                    event.stopPropagation();
                    if (paramsComponent.is_trusty) {
                        navigate(Links.screens.panel.people.trusties.trustyProfile + `${paramsComponent.profile.id}/`)
                    }
                    else {
                        navigate(Links.screens.panel.people.customers.customersProfile + `${paramsComponent.profile.id}/`)
                    }
                }}
            >
                {confComps.translate.openProfile}
            </p>
        </div>
    )
};
export default OpenProfile;