import { Box, Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { InterfaceAllReceptionRate, InterfaceAPIGetAllReceptionRate } from "./InterfaceAllReceptionRate";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import { useEffect, useState } from "react";
import { InterfaceNTTCashAccount } from "../../../../../../../NTTs/InterfaceNTTCashAccount";
import ButtonSave from "../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../../../api/API";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../../../../api/UrlsList";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { InterfaceNTTReceptionRate } from "../../../../../../../NTTs/InterfaceNTTReceptionRate";
import RowReceptionRate from "./RowReceptionRate/RowReceptionRate";
import CreateReceptionRate from "../CreateReceptionRate/CreateReceptionRate";
import ParentWithHeightAnimation from "../../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import InfiniteScrollList from "../../../../../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import { EnumStateFetchList } from "../../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfacePaginationInServer } from "../../../../../../../api/interfaces/InterfaceSearchInServer";

function AllReceptionRate(paramsComponent: InterfaceAllReceptionRate) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getResponseGetAllReceptionRate, setResponseGetAllReceptionRate] = useState<InterfaceAPIGetAllReceptionRate>();
    const [getIsActiveCreateReceptionRate, setIsActiveCreateReceptionRate] = useState<boolean>(false);
    const [getSelectedReceptionRate, setSelectedReceptionRate] = useState<InterfaceNTTReceptionRate | undefined>();

    const callApiForGetAllReceptionRate = async (paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.rate.receptionRate.getAllReceptionRate;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/reception-rates/`, mTheme, paginationParams as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIGetAllReceptionRate;
                // setResponseGetAllReceptionRate(t);
                if (paginationParams.page === 1) {
                    setResponseGetAllReceptionRate(t);
                }
                else {
                    setResponseGetAllReceptionRate(prev => {
                        return (prev ? {
                            ...t,
                            data: [...prev.data, ...t.data],
                        } : {
                            ...t,
                        });
                    });
                }
                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }

                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                setStateFetchList(EnumStateFetchList.errorInRequest);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            callApiForGetAllReceptionRate(getPaginationForLastRequest);
        }
    }, [getStateFetchList])

    return (
        <div style={{
            display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center",
            gap: "1.6rem",
        }}>
            <ParentWithHeightAnimation in={!getIsActiveCreateReceptionRate} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>

                <Box sx={{
                    width: "100%",
                    paddingInline: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                    paddingBlock: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                    boxSizing: "border-box",
                }}>
                    <InfiniteScrollList
                        isDataExist={(getResponseGetAllReceptionRate?.data ?? []).length !== 0}
                        onEndReached={async () => {
                            let oldPage = getPaginationForLastRequest;
                            oldPage = {
                                ...oldPage,
                                page: (oldPage.page + 1)
                            };
                            setPaginationForLastRequest(oldPage);
                            console.log("callback for change state response2:", oldPage);
                            callApiForGetAllReceptionRate(oldPage);
                        }}
                        onRetryClicked={() => {
                            callApiForGetAllReceptionRate(getPaginationForLastRequest);
                        }}
                        stateResponseList={getStateFetchList}
                        styleSpacingMUI={{
                            columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                            rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                            columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                        }}
                    >
                        <Grid container
                            justifyContent="space-between" alignItems="flex-start"
                            columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 3, xl: 5 }}
                            rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >


                            {getResponseGetAllReceptionRate && getResponseGetAllReceptionRate.data.map((changeRate, index, arr) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                        <RowReceptionRate
                                            {...changeRate}
                                            callbackClickOnEdit={() => {
                                                setSelectedReceptionRate(changeRate);
                                                setIsActiveCreateReceptionRate(true);
                                            }}
                                            callbackReloadPage={() => {
                                                callApiForGetAllReceptionRate(getPaginationForLastRequest);
                                            }} />
                                    </Grid>

                                )
                            })}
                        </Grid>
                    </InfiniteScrollList>
                </Box>
            </ParentWithHeightAnimation>
            <ParentWithHeightAnimation
                in={getIsActiveCreateReceptionRate} timeout={300} style={{
                    gap: "0.5rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >

                <CreateReceptionRate
                    userInfoBasic={paramsComponent.userInfoBasic}
                    callbackCancel={() => {
                        setIsActiveCreateReceptionRate(false);
                        setSelectedReceptionRate(undefined);
                        setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                        callApiForGetAllReceptionRate(confComps.defaultValue.paginationConfigForServer);
                    }}
                    defaultReceptionRate={getSelectedReceptionRate}
                />

            </ParentWithHeightAnimation>

            {!getIsActiveCreateReceptionRate &&
                <div style={{ display: 'flex', flexDirection: "row", position: "sticky", bottom: "1vh", alignItems: "center", justifyContent: "end", width: "100%" }}>
                    <ButtonSave text={confComps.translate.addNewReceptionRate}
                        callbackOnClick={() => {
                            setIsActiveCreateReceptionRate(true);
                        }} />
                </div>
            }
        </div>
    );
};
export default AllReceptionRate;
