import { InterfaceIconSVG } from "../InterfaceIconSVG"

export const NotificationIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0749 16.1905L18.8671 16.0072C18.2776 15.482 17.7617 14.8797 17.3332 14.2167C16.8652 13.3016 16.5848 12.3022 16.5082 11.2772V8.25833C16.5123 6.64842 15.9283 5.09245 14.866 3.88276C13.8037 2.67306 12.3363 1.89291 10.7393 1.68888V0.90055C10.7393 0.684178 10.6534 0.476667 10.5004 0.323669C10.3474 0.17067 10.1399 0.0847168 9.92349 0.0847168C9.70712 0.0847168 9.49961 0.17067 9.34661 0.323669C9.19361 0.476667 9.10766 0.684178 9.10766 0.90055V1.70111C7.52504 1.91984 6.07531 2.70471 5.02698 3.91034C3.97866 5.11597 3.40277 6.66067 3.40599 8.25833V11.2772C3.32945 12.3022 3.04897 13.3016 2.58099 14.2167C2.15986 14.8781 1.65222 15.4803 1.07155 16.0072L0.86377 16.1905V17.9139H19.0749V16.1905Z"
                fill={style?.background ? `${style?.background}` : undefined} />
            <path d="M8.3623 18.5555C8.41589 18.9429 8.60784 19.2978 8.9027 19.5547C9.19756 19.8116 9.57541 19.9531 9.96647 19.9531C10.3575 19.9531 10.7354 19.8116 11.0302 19.5547C11.3251 19.2978 11.517 18.9429 11.5706 18.5555H8.3623Z"
                fill={style?.background ? `${style?.background}` : undefined} />
        </svg>
    )
}
