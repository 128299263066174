import { useTheme } from "@mui/material";
import { InterfaceParentOfAllSolutions } from "./InterfaceParentOfAllSolutions";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonSave from "../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import Colors from "../../../../../../../config/Colors";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { EnumSTepsParent, EnumSTepsRecap, EnumSTepsSolutionRecap } from "../../../../../../../config/enums/EnumSteps";
import { InterfaceNTTRecapMain } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { InterfaceNTTRecapMoneyReceiveService } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMoneyReceiveService";
import { InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap, StepNameMappingToKeyStepForMoneyReceiveRecap } from "../../../../../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import { InterfaceAPIResponseGetMetaDataAddSolutions, InterfaceAPIResponseGetOneSolution } from "../ViewAddSolutionForMoneyReceive/InterfaceViewAddSolutionForMoneyReceive";
import ParentOfOneSolution from "./ParentOfOneSolution/ParentOfOneSolution";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { ThumbUpRounded } from "@mui/icons-material";
import ButtonCancel from "../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";

function ParentOfAllSolutions(paramsComponent: InterfaceParentOfAllSolutions) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getInfoServiceMoneyReceive, setInfoServiceMoneyReceive] = useState<InterfaceNTTRecapMoneyReceiveService>(paramsComponent.infoServiceInMainRecap);
    const [getInfoThisWorkFlow, setInfoThisWorkFlow] = useState<InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap>();
    const [getInfoThisStep, setInfoThisStep] = useState<InterfaceAPIResponseGetMetaDataAddSolutions>();
    const [getAllSolutionsInfo, setAllSolutionsInfo] = useState<InterfaceAPIResponseGetOneSolution[]>([]);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>('1');
    const [getAllWorkflowReviews, setAllWorkflowReviews] = useState<InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap[]>([]);
    const [getClickInButtonForSubmit, setClickInButtonForSubmit] = useState<{ itemClicked: string; time: number; }>({ itemClicked: "", time: -1 });
    const callApiForRetrieveMetaDataInfo = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyReceive.steps.addSolutions.metaData.getInfoThisStep;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getInfoThisWorkFlow?.review_id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIResponseGetMetaDataAddSolutions;
                setInfoThisStep(t);


                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForRetrieveAllSolutionsOfThisServiceInfo = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyReceive.steps.addSolutions.stepSolution.getListSolutions;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getInfoServiceMoneyReceive?.id}/solutions/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIResponseGetOneSolution[];
                setAllSolutionsInfo(t);
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }



    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setInfoServiceMoneyReceive(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setAllWorkflowReviews(paramsComponent.allWorkflowReviews);
    }, [paramsComponent.allWorkflowReviews])
    useEffect(() => {
        if (getInfoThisWorkFlow) {
            callApiForRetrieveMetaDataInfo();
            callApiForRetrieveAllSolutionsOfThisServiceInfo();
        }
    }, [getInfoThisWorkFlow]);
    useEffect(() => {
        setInfoThisWorkFlow(paramsComponent.thisStepWorkFlow);
    }, [paramsComponent.thisStepWorkFlow])
    return (

        < div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center',
            justifyContent: 'start',
            gap: '1.48rem',
            width: '100%',
            boxSizing: 'border-box',
            marginTop: ''
        }
        }>
            {
                getAllSolutionsInfo.map((solution, index, arr) => {
                    return (
                        <div style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'start',
                            justifyContent: 'start',
                            gap: '1rem',
                            width: '100%',
                            paddingInline: '1.48rem',
                            paddingBlock: '1.33rem',
                            boxSizing: 'border-box',
                            boxShadow: Colors.shadow.BoxShadow,
                            borderRadius: '1.11rem',
                        }}>
                            <h4 style={{
                                color: Colors.theme.orange.light,
                                fontFamily: confComps.font.poppins.Medium,
                                fontSize: '1.05rem',
                                fontWeight: 600,
                                lineHeight: '1.55rem',
                            }}>
                                {`Solution ${index + 1}`}
                            </h4>
                            {solution.description_list.length >= (solution.user_orders ?? []).length &&
                                <p style={{
                                    display: 'flex', flexDirection: 'row', alignItems: 'start',
                                    justifyContent: 'start',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    fontSize: '1.03rem',
                                    lineHeight: '1.62rem',
                                    color: Colors.theme.blue.dark,
                                }}>
                                    {solution.description_list.map((text, index, arr) => {
                                        if (index < (solution.user_orders ?? []).length) {
                                            return `${text} @${(solution.user_orders ?? [])[index].user.username}`
                                        }
                                        else {
                                            return `${text}`
                                        }
                                    }).join("")}
                                </p>
                            }
                            {solution.description_list.length < (solution.user_orders ?? []).length &&
                                <p style={{
                                    display: 'flex', flexDirection: 'row', alignItems: 'start',
                                    justifyContent: 'start',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    fontSize: '1.03rem',
                                    lineHeight: '1.62rem',
                                    color: Colors.theme.blue.dark,
                                }}>
                                    {(solution.user_orders ?? []).map((userMentioned, index, arr) => {
                                        if (index < solution.description_list.length) {
                                            return `${solution.description_list[index]} @${userMentioned.user.username}`
                                        }
                                        else {
                                            return `@${userMentioned.user.username}`
                                        }
                                    }).join("")}
                                </p>
                            }
                            <ParentOfOneSolution
                                stepThatSelected={getKeyOfStepThatSelected}
                                recapMain={getRecapMain}
                                infoServiceInMainRecap={getInfoServiceMoneyReceive}
                                StepWorkFlow={getInfoThisWorkFlow}
                                allWorkflowReviews={getAllWorkflowReviews}
                                infoSolution={solution}
                                callBackReLoad={() => {
                                    paramsComponent.callBackReLoad();
                                }}
                                clickOnButtonInParent={getClickInButtonForSubmit}
                            />
                        </div>
                    )
                })
            }
            {
                getAllWorkflowReviews.length > 0 &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}` &&//this step selected
                StepNameMappingToKeyStepForMoneyReceiveRecap[getAllWorkflowReviews[getAllWorkflowReviews.length - 1].review_ct]
                === `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.addSolution}` &&//previews step name
                <div style={{
                    display: 'flex', flexDirection: 'row', width: '100%',
                    justifyContent: 'end',
                    alignItems: 'center',
                }}>
                    <ButtonCancel text={confComps.translate.cancel}
                        callbackOnClick={() => {
                            setClickInButtonForSubmit({ itemClicked: "disagree", time: Date.now() });
                        }}
                    />
                    <ButtonSave
                        text={confComps.translate.agree}
                        callbackOnClick={() => {
                            setClickInButtonForSubmit({ itemClicked: "agree", time: Date.now() })
                        }}
                        iconStart={<ThumbUpRounded
                            style={{
                                fontSize: '1.1rem',
                            }} />
                        }
                    />

                </div>
            }

        </div >
    );
}
export default ParentOfAllSolutions;