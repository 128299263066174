import { DoneRounded, CircleOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TitleMedium16 from '../../../../components/basic/Texts/Titles/TitleMedium16/TitleMedium16';
import BoxRecap from '../../../../components/basic/‌Box/BoxRecap/BoxRecap';
import Colors from '../../../../config/Colors';
import { ConfigComps } from '../../../../config/ConfigCOMP';
import { SelectedProfile } from '../../../../store/profileStateSlice';
import { InterfaceParentRecapSellCottage } from './InterfaceParentRecapSellCottage';
import { InterfaceNTTRecapMain } from '../../../../NTTs/Recap/InterfaceNTTRecapMain';
import { InterfaceNTTRecapSellCottageService } from '../../../../NTTs/Recap/InterfaceNTTRecapSellCottageService';
import ControllerInitiateSellCottage from '../StepersBox/SellCottage/InitiateSellCottage/ControllerInitiateSellCottage';
function ParentRecapSellCottage(paramsComponent: InterfaceParentRecapSellCottage) {
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>('1');
    const [getIsCreateSellCottageActive, setIsCreateSellCottageActive] = useState<boolean>(true);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getInfoServiceInMainRecap, setInfoServiceInMainRecap] = useState<InterfaceNTTRecapSellCottageService>();
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setInfoServiceInMainRecap(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])

    return (
        <BoxRecap>
            <div
                style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'space-between',
                    boxSizing: 'border-box',
                }}
            >
                <TitleMedium16>
                    {confComps.translate.sellCottage}
                </TitleMedium16>
                {getKeyOfStepThatSelected === '1.1' &&
                    <div
                        style={{
                            cursor: 'pointer',
                            display: 'inline-flex',
                        }}
                        onClick={() => {
                            if ((getRecapMain?.payments ?? []).length === 0)
                                setIsCreateSellCottageActive(prev => !prev)
                        }}
                    >
                        {getIsCreateSellCottageActive ?
                            <DoneRounded style={{
                                color: '#fff', background: Colors.theme.blue.blue,
                                borderRadius: '500px', fontSize: '1.5rem',
                                padding: '0.15rem',
                            }} /> :
                            <CircleOutlined style={{ color: Colors.theme.blue.blue, fontSize: '1.8rem' }} />
                        }
                    </div>
                }
            </div>
            <ControllerInitiateSellCottage
                infoServiceInMainRecap={getInfoServiceInMainRecap}
                callBackCreateMainRecap={paramsComponent.callBackCreateMainRecap}
                recapMain={getRecapMain}
                isCreateSellCottageActive={getIsCreateSellCottageActive}
                stepThatSelected={getKeyOfStepThatSelected}
                callBackReLoad={() => {
                    console.log('callback reload caled:)')
                    paramsComponent.callBackReLoad();
                }} />

        </BoxRecap>
    )

}
export default ParentRecapSellCottage;