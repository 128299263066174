import { Box, Grid, styled, useTheme } from "@mui/material";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { ImageSquare } from "../../../../components/complex/imageSquare/ImageSquare";
import Colors, { ColorThemForButton } from "../../../../config/Colors";
import { AddRounded, CheckCircleOutlineTwoTone, CheckCircleTwoTone, CheckRounded, FileUploadRounded, Person, Person2Rounded, PlusOneRounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import LineSeparator from "../../../../components/basic/LineSeprator/LineSeparator";
import ArrayText from "../../../../components/basic/Texts/ArrayText/ArrayText";
import { getColorCodeOfBalanceNumber } from "../../../../helper/Helper";
import { useNavigate } from "react-router-dom";
import Links from "../../../../config/Links";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../api/API";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { InterfaceRowRecap } from "./InterfaceRowRecap";
import OpenProfile from "../../../../components/basic/OpenProfile/OpenProfile";
import dayjs from "dayjs";
import ProgressBarGradient from "../../../../components/basic/progressBar/ProgressBarGradient/ProgressBarGradient";
import { TickWithCircleIcon } from "../../../../assets/icons/svg/TickWithCircleIcon";
import ButtonColor from "../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import { ArrowRightIcon } from "../../../../assets/icons/svg/ArrowRightIcon";
const ParentDiv = styled("div")(({ theme }) => ({
    width: "100%", padding: "1px",
    background: "white",
    boxShadow: Colors.shadow.cardList,
    borderRadius: "0.5rem",
    cursor: "pointer",
    "&:hover": {
        background: Colors.gradients.blueGreenBlue,
    }
}));
const TagService = styled("div")(({ theme }) => ({
    background: Colors.theme.white.PrimaryLight,
    color: Colors.theme.blue.dark,
    boxShadow: Colors.shadow.cardList,
    paddingInline: "0.5rem",
    paddingBlock: "0.16rem",
    borderRadius: "500px",
    boxSizing: "border-box",
    lineHeight: "1.2rem",
    fontSize: "1rem",


}));
function RowRecap(paramsComponent: InterfaceRowRecap) {
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const callApiForDeleteEmployee = async (idPerson: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.employee.deleteTheEmployee;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idPerson}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackReloadPage();
                dispatch(ShowAlert({ text: confComps.translate.removedEmployee, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <ParentDiv style={{ height: "100%" }}
            onClick={() => {
                navigate(Links.screens.panel.recap.ShowStateTheRecap + `${paramsComponent.id}/`)
            }}>
            <Box

                sx={{
                    background: "white",
                    height: "100%",
                    width: "100%", display: "flex", flexDirection: "column",
                    alignItems: "center", justifyContent: "space-between",
                    borderRadius: "0.5rem",
                    paddingInline: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 },//{{ xs: "0.96rem" }},
                    paddingTop: "1.28rem",
                    paddingBottom: { xs: 1, sm: 1, md: 2, lg: 3, xl: 4 },
                    boxSizing: "border-box",

                }}>
                <Grid container
                    justifyContent="flex-start" alignItems="stretch"
                    columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 2, xl: 4 }}
                    columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                >
                    {paramsComponent.receipts.flatMap((payment, index, arr) => {
                        return [
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <OpenProfile user={payment.customer.user}
                                    profile={payment.customer.profile}
                                    is_trusty={payment.customer.is_trusty}
                                />
                            </Grid>
                            ,
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <TagService>
                                        {confComps.translate.moneyReceive}
                                    </TagService>
                                </div>
                            </Grid>
                        ]
                    })
                    }
                    {paramsComponent.payments.flatMap((payment, index, arr) => {
                        return [
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <OpenProfile user={payment.customer.user} is_trusty={payment.customer.is_trusty}
                                    profile={payment.customer.profile}
                                />
                            </Grid>
                            ,
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <TagService>
                                        {confComps.translate.moneyPayment}
                                    </TagService>
                                </div>
                            </Grid>
                        ]
                    })
                    }
                    {paramsComponent.buy_cottages.flatMap((payment, index, arr) => {
                        return [
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <OpenProfile user={payment.customer.user} is_trusty={payment.customer.is_trusty}
                                    profile={payment.customer.profile} />
                            </Grid>
                            ,
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <TagService>
                                        {confComps.translate.buyCottage}
                                    </TagService>
                                </div>
                            </Grid>
                        ]
                    })
                    }

                    {paramsComponent.sell_cottages.flatMap((payment, index, arr) => {
                        return [
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <OpenProfile user={payment.customer.user} is_trusty={payment.customer.is_trusty}
                                    profile={payment.customer.profile} />
                            </Grid>
                            ,
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <TagService>
                                        {confComps.translate.sellCottage}
                                    </TagService>
                                </div>
                            </Grid>
                        ]
                    })
                    }
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <ArrayText
                            texts={[
                                {
                                    text: `${confComps.translate.recapID}: `,
                                    style: {
                                        color: Colors.theme.blue.light,
                                    }
                                },
                                {
                                    text: `${paramsComponent.id}`,
                                    style: {
                                        color: Colors.theme.blue.dark,
                                    }

                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <ArrayText
                            texts={[
                                {
                                    text: `${confComps.translate.date}: `,
                                    style: {
                                        color: Colors.theme.blue.light,
                                    }
                                },
                                {
                                    text: `${dayjs.unix(paramsComponent.created_at).format("YYYY/MM/DD")}`,
                                    style: {
                                        color: Colors.theme.blue.dark,
                                    }

                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <ProgressBarGradient percentage={70} label={{
                            title: "",
                            link: ""
                        }} />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: "0.32rem" }}>
                            <TickWithCircleIcon style={{ color: Colors.theme.green.dark, background: Colors.theme.green.lightLemon }} />
                            <h5 style={{
                                fontSize: "0.8rem", fontFamily: confComps.font.poppins.SemiBold, lineHeight: "1.4rem",
                                color: Colors.theme.green.dark
                            }}>
                                {confComps.translate.completed}

                            </h5>
                        </div>

                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={2} xl={2}>
                        <div style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "-1rem",
                            justifyContent: 'end',

                        }}>
                            <ButtonColor text={confComps.translate.showDetails}
                                onclick={function (): void {
                                    navigate(Links.screens.panel.recap.ShowStateTheRecap + `${paramsComponent.id}/`)
                                }}
                                iconStart={undefined}
                                color={ColorThemForButton.grayColorAndTransparentBackground}
                                iconEnd={<ArrowRightIcon />}
                            />

                        </div>
                    </Grid>


                </Grid>

            </Box>
        </ParentDiv >
    )
}
export default RowRecap;