import { HamburgerMenu } from "../../../assets/icons/svg/HamburgerMenuIcon";
import { HomeIcon } from "../../../assets/icons/svg/HomeIcon";
import { PeopleIcon } from "../../../assets/icons/svg/PeopleIcon";
import { RecapIcon } from "../../../assets/icons/svg/RecapIcon";
import { DealsIcon } from "../../../assets/icons/svg/DealsIcon";
import Colors from "../../../config/Colors";
import { interfaceSidebar } from "./InterfaceSidebar";
import { BackSide, ItemMenu, ItemMenuText } from "./SidebarStyle";
import { ServicesIcon } from "../../../assets/icons/svg/ServicesIcon";
import { useTranslation } from "react-i18next";
import { ConfigComps } from "../../../config/ConfigCOMP";
import SubSidebar from "./subSidebar/SubSidebar";
import { useEffect, useState } from "react";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import { useLocation, useNavigate } from "react-router-dom";
import { EnumSectionsSidebar } from "../../../config/enums/EnumSectionsSidebar";
import { ImageSquare } from "../imageSquare/ImageSquare";
import { colors } from "@mui/material";
import { InterfaceItemOfSubSidebar } from "./subSidebar/InterfaceSubSidebar";
import HeaderSubSidebar from "../../basic/subSidebar/headerSubSidebar/HeaderSubSidebar";
function Sidebar(componentsParams: interfaceSidebar) {
    var confComps = ConfigComps();
    const location = useLocation();
    const navigate = useNavigate();
    const [getListOfSubSidebar, setListOfSubSidebar] = useState<InterfaceItemOfSubSidebar[]>([])
    const [getIsOpenSubSidebar, setIsOpenSubSidebar] = useState((componentsParams.typeOfDisplay <= enumListSizeDevices.tablet) ? false : true);
    const [getSelectedButton, setSelectedButton] = useState<EnumSectionsSidebar>();
    useEffect(() => {
        if (location.pathname.split("/").length > 2) {
            setSelectedButton(location.pathname.split("/")[2] as EnumSectionsSidebar);
        }
        console.log("componentsParams")
        console.log(componentsParams)
    }, []);
    useEffect(() => {
        if (getSelectedButton === EnumSectionsSidebar.myProfile || getSelectedButton === EnumSectionsSidebar.mySetting
            || getSelectedButton === EnumSectionsSidebar.myNotification || getSelectedButton === EnumSectionsSidebar.recap
            || getSelectedButton == EnumSectionsSidebar.dashboard) {
            setIsOpenSubSidebar(false)
        }
        else {
            setIsOpenSubSidebar(true);
            // if (getSelectedButton == EnumSectionsSidebar.dashboard) {
            //     setListOfSubSidebar(confComps.panel.dashboard.subSidebarItems)
            // }
            // else if (getSelectedButton == EnumSectionsSidebar.recap) {
            //     setListOfSubSidebar(confComps.panel.recap.subSidebarItems)
            // }
            if (getSelectedButton == EnumSectionsSidebar.deals) {
                setListOfSubSidebar(confComps.panel.deals.subSidebarItems)
            }
            if (getSelectedButton == EnumSectionsSidebar.services) {
                setListOfSubSidebar(confComps.panel.services.subSidebarItems)
            }
            if (getSelectedButton == EnumSectionsSidebar.people) {
                setListOfSubSidebar(confComps.panel.people.subSidebarItems)
            }
        }

    }, [getSelectedButton])

    return (
        <BackSide isSubSidebarOpen={getIsOpenSubSidebar}
            isWidthSmall={componentsParams.typeOfDisplay <= enumListSizeDevices.tablet}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
                width: "88px",
                flexBasis: "88px",
                flexGrow: "0",
                zIndex: "2",
                background: "inherit",
                paddingTop: "28px",
                boxSizing: "border-box",
            }}>
                <div style={{
                    flexBasis: "0",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "min(83px , 3vh)",
                }}>
                    <div
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setIsOpenSubSidebar(!getIsOpenSubSidebar);
                        }}>
                        <HamburgerMenu style={{ background: getIsOpenSubSidebar ? Colors.theme.blue.dark : Colors.theme.blue.blue }} />
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "8px",
                        width: "100%",

                    }}>
                        {confComps.sidebar.items.map((item, index, array) => {
                            return (
                                <ItemMenu isSelected={item.key === getSelectedButton}
                                    onClick={() => {
                                        setSelectedButton(item.key)
                                        if (item.link) {
                                            navigate(item.link ?? "");
                                        }
                                        else {
                                            if (!getIsOpenSubSidebar) {
                                                setIsOpenSubSidebar(true);
                                            }
                                        }
                                    }}>
                                    <item.icon style={{ background: item.key == getSelectedButton ? Colors.theme.blue.dark : Colors.theme.blue.blue }} />
                                    <ItemMenuText isSelected={item.key == getSelectedButton}>{item.title}</ItemMenuText>
                                </ItemMenu>
                            )
                        })}
                    </div>
                </div>
                <div style={{
                    width: "100%",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "start",

                }}>
                    {confComps.sidebar.itemConfig.map((item, index, array) => {
                        return (
                            <ImageSquare width={"56px"}
                                borderRadius="100%"
                                background={item.key == getSelectedButton ? Colors.theme.white.SecondaryLight : undefined}
                                element={
                                    <div style={{
                                        width: "100%", display: "flex", flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                        <item.icon style={{
                                            background: (item.key == getSelectedButton) ? Colors.theme.blue.dark : Colors.theme.blue.blue,
                                            color: (item.key == getSelectedButton) ? Colors.theme.blue.dark : Colors.theme.blue.blue
                                        }} />

                                    </div>
                                }
                                callbackOnclick={() => {
                                    setSelectedButton(item.key);
                                    navigate(item.link);

                                }}
                            />

                        )
                    })}
                </div>
            </div>
            <SubSidebar isOpen={getIsOpenSubSidebar}
                typeOfDisplay={componentsParams.typeOfDisplay}
                listOfItems={getListOfSubSidebar} />
        </BackSide>
    );
}
export default Sidebar;