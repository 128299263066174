import { EnumRolePerson } from "./EnumRolePerson"
export enum EnumSTepsParent {
    recap=1,
    project=2,
    service=3,
}
export enum EnumSTepsRecap {
    initiate=1,
    checkWalletOfTheRequesterByFundControl=2,
    complianceRiskMeasurement=3,
    solutions=4,
}
export enum EnumSTepsSolutionRecap {
    addSolution=1,
    solutionRiskMeasurement=2,
    checkSolutionsByFinance=3,
    checkSolutionsWithTrusties=4,
    chooseSolution=5,
    acceptByManager=6,
    summarize=7,
}