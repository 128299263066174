import { useEffect, useState } from "react";
import TrustyItem from "./Trusty/TrustyItem";
import { InterfaceTrustysList, InterfaceGetAPITrustysList } from "./InterfaceTrustiesList";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useTheme } from "@mui/material";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../../api/API";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../../api/UrlsList";
import { InterfacePaginationInServer, InterfaceSearchInServer } from "../../../../../api/interfaces/InterfaceSearchInServer";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { InterfaceTrustyItem } from "./Trusty/InterfaceTrustyItem";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import ContainerPage from "../../../../../components/basic/containerPage/ContainerPage";
import ShowStateFetchList from "../../../../../components/basic/ShowStateFetchList/ShowStateFetchList";
import { EnumStateFetchList } from "../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import AdvanceSearchBox from "../../../../../components/complex/advanceSearchBox/AdvanceSearchBox";
import SortingBox from "../../../../../components/complex/SortingBox/SortingBox";
import AddButtonWithShadowEffect from "../../../../../components/basic/Buttons/AddButtonWithShadowEffect/AddButtonWithShadowEffect";
import Colors from "../../../../../config/Colors";
import { EnumRolePerson } from "../../../../../config/enums/EnumRolePerson";
import Links from "../../../../../config/Links";
import { EnumSortingType, InterfaceItemOfSorting } from "../../../../../components/complex/SortingBox/InterfaceSortingBox";
import { InterfaceAdvanceBox, InterfaceAdvanceSearchParams } from "../../../../../components/complex/advanceSearchBox/InterfaceAdvanceSearchBox";
import InfiniteScrollList from "../../../../../components/basic/InfiniteScrollList/InfiniteScrollList";

function TrustiesList(paramsComponent: InterfaceTrustysList) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getSmartSearch, setSmartSearch] = useState<InterfaceAdvanceSearchParams>({ smartSearch: "", advanceParams: confComps.panel.people.trusties.advanceSearchDefaultParams });
    const [getResponseTrustys, setResponseTrustys] = useState<InterfaceGetAPITrustysList>();
    const [getSelectedItemOfSorting, setSelectedItemOfSorting] = useState<InterfaceItemOfSorting[]>(confComps.sorting.customerList);
    const profileInStore = useSelector(SelectedProfile);

    const getApiListOfTrusties = async (sortingFilter: InterfaceItemOfSorting[], advanceSearch: InterfaceAdvanceSearchParams, paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.trusty.getTrustyList;
        let bodyObj: InterfaceSearchInServer = ConvertSmartSearchItemToItemSearchForServer(sortingFilter, advanceSearch)
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme, paginationParams as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceGetAPITrustysList;
                if (paginationParams.page === 1) {
                    setResponseTrustys(t);
                }
                else {
                    setResponseTrustys(prev => {
                        return (prev ? {
                            ...t,
                            data: [...prev.data, ...t.data],
                        } : {
                            ...t,
                        });
                    }
                    );
                }



                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }


                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                setStateFetchList(EnumStateFetchList.errorInRequest);
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            getApiListOfTrusties(getSelectedItemOfSorting, getSmartSearch, getPaginationForLastRequest);
        }
    }, [getStateFetchList])
    return (
        <ContainerPage title={confComps.translate.trustyList}>
            <div style={{
                width: "100%", display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
                gap: "0.96rem"
            }}>
                <AdvanceSearchBox
                    defaultValue={getSmartSearch}
                    doneSearching={(params: InterfaceAdvanceSearchParams) => {
                        getApiListOfTrusties(getSelectedItemOfSorting, params, confComps.defaultValue.paginationConfigForServer);
                        setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                        setSmartSearch(params);
                    }}
                />
                <SortingBox
                    items={getSelectedItemOfSorting}
                    onclickDone={(items: InterfaceItemOfSorting[]) => {
                        getApiListOfTrusties(items, getSmartSearch, confComps.defaultValue.paginationConfigForServer);
                        setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                        setSelectedItemOfSorting(items);
                    }}
                    limitClick={-1}
                />
                <AddButtonWithShadowEffect
                    text={confComps.translate.trusty}
                    callbackOnclick={() => {
                        navigate(Links.screens.panel.people.trusties.addTrusty + `${EnumRolePerson.trusty}/`)

                    }}
                />
            </div>
            <div style={{
                display: "flex", boxSizing: "border-box", flexDirection: "column", alignItems: "center", justifyContent: "space-between",
                width: "100%", flexGrow: 1,
                background: Colors.theme.white.white80,
                border: `1px solid ${Colors.theme.white.white70}`,
                padding: "1.6rem",
                borderRadius: "0.32rem",
            }}
            >
                <InfiniteScrollList
                    isDataExist={(getResponseTrustys?.data ?? []).length !== 0}
                    onEndReached={async () => {
                        let oldPage = getPaginationForLastRequest;
                        oldPage = {
                            ...oldPage,
                            page: (oldPage.page + 1)
                        };
                        setPaginationForLastRequest(oldPage);
                        getApiListOfTrusties(getSelectedItemOfSorting, getSmartSearch, oldPage);
                    }}
                    onRetryClicked={() => {
                        getApiListOfTrusties(getSelectedItemOfSorting, getSmartSearch, getPaginationForLastRequest);
                    }}
                    stateResponseList={getStateFetchList}
                    styleSpacingMUI={{
                        columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                        rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                        columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                    }}

                >
                    <GridFromMUI container
                        justifyContent="flex-start" alignItems="stretch"
                        columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                        rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 3, xl: 4 }}
                        columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 4 }}>
                        {getResponseTrustys && getResponseTrustys.data.map((customer, index: number) => {
                            return (
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    <TrustyItem
                                        callbackReloadPage={() => {
                                            getApiListOfTrusties(getSelectedItemOfSorting, getSmartSearch, confComps.defaultValue.paginationConfigForServer);
                                            setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                                        }}
                                        isSelected={false}
                                        {...customer}
                                        key={index}
                                    />
                                </Grid>
                            )
                        })}
                    </GridFromMUI>
                </InfiniteScrollList>
            </div >
        </ContainerPage>
    )
}
export default TrustiesList;