import { DoneRounded, CircleOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TitleMedium16 from "../../../../components/basic/Texts/Titles/TitleMedium16/TitleMedium16";
import BoxRecap from "../../../../components/basic/‌Box/BoxRecap/BoxRecap";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { InterfaceParentRecapMoneyReceive } from "./InterfaceParentRecapMoneyReceive";
import { InterfaceNTTRecapMain } from "../../../../NTTs/Recap/InterfaceNTTRecapMain";
import ControllerInitiateMoneyReceive from "../StepersBox/MoneyReceive/InitiateMoneyReceive/ControllerInitiateMoneyReceive";
import { InterfaceNTTRecapMoneyReceiveService } from "../../../../NTTs/Recap/InterfaceNTTRecapMoneyReceiveService";
import ControllerCheckWalletCustomerOfMoneyReceive from "../StepersBox/MoneyReceive/CheckWalletCustomerOfMoneyReceive/ControllerCheckWalletCustomerOfMoneyReceive";
import { isKey1BiggerThanKey2 } from "../../../../components/complex/SteperGuide/SteperGuide";
import { EnumSTepsParent, EnumSTepsRecap, EnumSTepsSolutionRecap } from "../../../../config/enums/EnumSteps";
import { StepNameMappingToKeyStepForMainExplanationRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/MainExplanation/InterfaceNTTOneStepOfWorkflowMainExplanationRecap";
import { EnumRecapServiceStepNameInServerForMoneyReceive, InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap, StepNameMappingToKeyStepForMoneyReceiveRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap";
import { InterfaceNTTOneServiceWorkflowMoneyReceiveRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneServiceWorkflowMoneyReceiveRecap";
import ControllerRiskMeasurementCustomerOfMoneyReceive from "../StepersBox/MoneyReceive/RiskMeasurementCustomerOfMoneyReceive/ControllerRiskMeasurementCustomerOfMoneyReceive";
import ControllerAddSolutionForMoneyReceive from "../StepersBox/MoneyReceive/AddSolutionForMoneyReceive/ControllerAddSolutionForMoneyReceive";

function ParentRecapMoneyReceive(paramsComponent: InterfaceParentRecapMoneyReceive) {
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1");
    const [getIsCreateMoneyReceiveActive, setIsCreateMoneyReceiveActive] = useState<boolean>(false);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getInfoServiceInMainRecap, setInfoServiceInMainRecap] = useState<InterfaceNTTRecapMoneyReceiveService>();
    const [getInfoWorkFlowService, setInfoWorkFlowService] = useState<InterfaceNTTOneServiceWorkflowMoneyReceiveRecap>();
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setInfoServiceInMainRecap(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setInfoWorkFlowService(paramsComponent.infoWorkFlowService);
    }, [paramsComponent.infoWorkFlowService])

    return (
        <BoxRecap>
            <div
                style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'space-between',
                    boxSizing: "border-box",
                }}
            >
                <TitleMedium16>
                    {confComps.translate.moneyReceive}
                </TitleMedium16>
                {getKeyOfStepThatSelected === "1.1" &&
                    <div
                        style={{
                            cursor: "pointer",
                            display: 'inline-flex',
                        }}
                        onClick={() => {
                            if ((getRecapMain?.receipts ?? []).length === 0)
                                setIsCreateMoneyReceiveActive(prev => !prev)
                        }}
                    >
                        {getIsCreateMoneyReceiveActive ?
                            <DoneRounded style={{
                                color: "#fff", background: Colors.theme.blue.blue,
                                borderRadius: "500px", fontSize: "1.5rem",
                                padding: "0.15rem",
                            }} /> :
                            <CircleOutlined style={{ color: Colors.theme.blue.blue, fontSize: "1.8rem" }} />
                        }
                    </div>
                }
            </div>
            <ControllerInitiateMoneyReceive
                infoServiceInMainRecap={getInfoServiceInMainRecap}
                callBackCreateMainRecap={paramsComponent.callBackCreateMainRecap}
                recapMain={getRecapMain}
                isCreateMoneyReceiveActive={getIsCreateMoneyReceiveActive}
                stepThatSelected={getKeyOfStepThatSelected}
                callBackReLoad={() => {
                    paramsComponent.callBackReLoad();
                }}
            />
            {getInfoServiceInMainRecap &&
                getInfoWorkFlowService?.workflow_reviews
                    .filter(step => isKey1BiggerThanKey2(getKeyOfStepThatSelected, StepNameMappingToKeyStepForMoneyReceiveRecap[step.review_ct])
                        || getKeyOfStepThatSelected === StepNameMappingToKeyStepForMoneyReceiveRecap[step.review_ct])//check that view by step that clicked in top guide
                    .map((oneStep, index, arr) => {
                        if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyReceive.fundControlCheckWalletCustomer) {
                            return (
                                <ControllerCheckWalletCustomerOfMoneyReceive
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    recapMain={getRecapMain}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    callBackReLoad={() => {
                                        paramsComponent.callBackReLoad();
                                    }}
                                    StepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyReceive.checkRisk) {
                            return (
                                <ControllerRiskMeasurementCustomerOfMoneyReceive
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    StepWorkFlow={oneStep}
                                    callBackReLoad={() => {
                                        paramsComponent.callBackReLoad();
                                    }}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyReceive.addSolution) {
                            return (
                                <ControllerAddSolutionForMoneyReceive
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    StepWorkFlow={oneStep}
                                    callBackReLoad={() => {
                                        paramsComponent.callBackReLoad();
                                    }}
                                />
                            )
                        }
                    })
            }

            {/* step that now must be fill */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.checkWalletOfTheRequesterByFundControl}` && // step guide click this step
                StepNameMappingToKeyStepForMoneyReceiveRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForMoneyReceive.undefined }).review_ct
                ] === StepNameMappingToKeyStepForMoneyReceiveRecap[EnumRecapServiceStepNameInServerForMoneyReceive.undefined]
                &&
                <ControllerCheckWalletCustomerOfMoneyReceive
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    recapMain={getRecapMain}
                    stepThatSelected={getKeyOfStepThatSelected}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    StepWorkFlow={undefined}
                />
            }
            {/* fill the risk measurement of customer that selected at first step */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}` && // step guide click this step
                StepNameMappingToKeyStepForMoneyReceiveRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForMoneyReceive.undefined }).review_ct
                ] === StepNameMappingToKeyStepForMoneyReceiveRecap[EnumRecapServiceStepNameInServerForMoneyReceive.fundControlCheckWalletCustomer]//the last step that completed must the previous step
                &&
                <ControllerRiskMeasurementCustomerOfMoneyReceive
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={undefined}
                />
            }
            {/* fill the solutions of customer that selected at first step */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.addSolution}` && // step guide click this step
                StepNameMappingToKeyStepForMoneyReceiveRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForMoneyReceive.undefined }).review_ct
                ] === StepNameMappingToKeyStepForMoneyReceiveRecap[EnumRecapServiceStepNameInServerForMoneyReceive.checkRisk]//the last step that completed must the previous step
                &&
                <ControllerAddSolutionForMoneyReceive
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={undefined}
                />
            }

        </BoxRecap>
    )

}
export default ParentRecapMoneyReceive;