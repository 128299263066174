import { useNavigate } from "react-router-dom";
import { InterfaceCreateRecap } from "./InterfaceCreateRecap";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../config/ConfigCOMP";
import ContainerPage from "../../../components/basic/containerPage/ContainerPage";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { MainCard } from "../../myProfile/MyProfileStyle";
import { useState } from "react";
import { EnumStateStepGroup } from "../../../components/basic/ArrowStep/InterfaceArrowStep";
import SteperGroups from "../../../components/complex/SteperGuide/SteperGroup/SteperGroup";
import SteperGuide from "../../../components/complex/SteperGuide/SteperGuide";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import TitleMedium18 from "../../../components/basic/Texts/Titles/TitleMedium18/TitleMedium18";
import BoxRecap from "../../../components/basic/‌Box/BoxRecap/BoxRecap";
import TitleMedium16 from "../../../components/basic/Texts/Titles/TitleMedium16/TitleMedium16";
import { CheckCircleOutlineRounded, CircleOutlined, DoneRounded } from "@mui/icons-material";
import Colors from "../../../config/Colors";
import ParentWithHeightAnimation from "../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import TextFieldSimple from "../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import ParentRecapMoneyReceive from "../ParentRecapService/ParentRecapMoneyRecieve/ParentRecapMoneyRecieve";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { EnumStateFetchList } from "../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfaceSearchInServer } from "../../../api/interfaces/InterfaceSearchInServer";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../api/UrlsList";
import { RequestHandler } from "../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { InterfaceGetAPICustomersList } from "../../people/TrustyOrCustomer/customers/customersList/InterfaceCustomersList";
import { InterfaceNTTRecapMain } from "../../../NTTs/Recap/InterfaceNTTRecapMain";
import ParentRecapMoneyPayment from "../ParentRecapService/ParentRecapMoneyPayment/ParentRecapMoneyPayment";
import ParentRecapBuyCottage from "../ParentRecapService/ParentRecapBuyCottage/ParentRecapBuyCottage";
import ParentRecapSellCottage from "../ParentRecapService/ParentRecapSellCottage/ParentRecapSellCottage";

function CreateRecap(paramsComponent: InterfaceCreateRecap) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("1.");
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const callApiForCreateRecapMain = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.postCreateRecapMainInitiate;
        let bodyObj = {

        }

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                setRecapMain(response);
                // let t = response as InterfaceGetAPICustomersList;

                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    return (
        <ContainerPage title={confComps.translate.newRecap}>
            <SteperGuide
                steps={[confComps.steperGuide.recap.steps]}
                callbackOnClickOneStep={(keySelected: string) => {
                    if (keySelected !== "1." && keySelected !== "1.1") {
                        dispatch(ShowAlert({ text: confComps.translate.youMustCreateAtFirst, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                    }

                }}
                callbackOnClickOneGroup={(keySelected: string) => {
                    setKeyOfGroupThatSelected(keySelected)
                }}
                keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                keyOfOneStepThatSelected={"1.1"}
                keyOfStepThatLastCompleted={"1.1"}
            />

            <MainCard sx={{
                gap: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "6vh" },
                display: 'flex', flexDirection: 'column', alignItems: 'start',
                justifyContent: 'start',
            }}>
                <TitleMedium18 style={{
                    boxSizing: "border-box",
                }}>
                    {confComps.translate.newRecap}
                </TitleMedium18>
                {/* money receive */}
                <ParentRecapMoneyReceive
                    infoWorkFlowService={undefined}
                    recapMain={getRecapMain}
                    stepThatSelected="1.1"
                    callBackCreateMainRecap={callApiForCreateRecapMain}
                    infoServiceInMainRecap={undefined}
                    callBackReLoad={() => {

                    }}
                />
                {/* money payment */}
                <ParentRecapMoneyPayment
                    recapMain={getRecapMain}
                    stepThatSelected="1.1"
                    callBackCreateMainRecap={callApiForCreateRecapMain}
                    infoServiceInMainRecap={undefined}
                    callBackReLoad={() => {
                    }}
                    infoWorkFlowService={undefined}
                />
                {/* Buy Cottage */}
                <ParentRecapBuyCottage
                    recapMain={getRecapMain}
                    stepThatSelected="1.1"
                    callBackCreateMainRecap={callApiForCreateRecapMain}
                    infoServiceInMainRecap={undefined}
                    callBackReLoad={() => {
                    }} infoWorkFlowService={undefined}
                />
                {/* Sale Cottage */}
                <ParentRecapSellCottage
                    recapMain={getRecapMain}
                    stepThatSelected="1.1"
                    callBackCreateMainRecap={callApiForCreateRecapMain}
                    infoServiceInMainRecap={undefined}
                    callBackReLoad={() => {
                    }} infoWorkFlowService={undefined}
                />




            </MainCard>
        </ContainerPage>

    );
}
export default CreateRecap;
