import { useEffect, useState } from "react";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { EnumStateStepGroup } from "../../basic/ArrowStep/InterfaceArrowStep";
import { InterfaceSteperGuide } from "./InterfaceSteperGuide";
import OneStep from "./SteperGroup/OneStep/OneStep";
import SteperGroup from "./SteperGroup/SteperGroup";
import Colors from "../../../config/Colors";
export function isTwoKeyHasSameParent(key1: string, key2: string) {
    let parents1 = key1.split(".");
    let parents2 = key2.split(".");
    let lengthThatMustReview = Math.min(parents1.length, parents2.length);

    for (let i = 0; i < (lengthThatMustReview - 1); i++) {
        if (parents1[i] != parents2[i]) {
            return false;
        }
    }
    return true;

}
export function getKeyOfParentOfKey(key1: string) {
    if (!key1.includes(".")) {
        return "";
    }
    let parents1 = key1.split(".");
    parents1.pop();
    return parents1.join(".");





}
export function isKey1BiggerThanKey2(key1: string, key2: string) {
    let parents1 = key1.split(".");
    let parents2 = key2.split(".");
    let lengthThatMustReview = Math.min(parents1.length, parents2.length);
    if (key1 === key2) {
        return false;
    }
    for (let i = 0; i < (lengthThatMustReview); i++) {
        if (Number(`${parents1[i]}`) < Number(`${parents2[i]}`)) {
            return false;
        }
    }
    return true;
}
function SteperGuide(paramsComponent: InterfaceSteperGuide) {
    var confComps = ConfigComps();
    const [getKeyOfOneStepThatSelected, setKeyOfOneStepThatSelected] = useState<string>(paramsComponent.keyOfOneStepThatSelected);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>(paramsComponent.keyOfGroupThatSelected);
    const [getKeyOfStepThatLastCompleted, setKeyOfStepThatLastCompleted] = useState<string>(paramsComponent.keyOfStepThatLastCompleted);
    useEffect(() => {
        setKeyOfStepThatLastCompleted(paramsComponent.keyOfStepThatLastCompleted);
    }, [paramsComponent.keyOfStepThatLastCompleted])
    useEffect(() => {
        setKeyOfOneStepThatSelected(paramsComponent.keyOfOneStepThatSelected);
    }, [paramsComponent.keyOfOneStepThatSelected])
    useEffect(() => {
        setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected);
    }, [paramsComponent.keyOfGroupThatSelected])
    return (
        <div style={{
            display: "inline-flex",
            position: "relative",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flexGrow: 0,
            height: "calc(4rem + 4px)",
            borderTop: `2px solid ${Colors.theme.blue.light}`,
            borderBottom: `2px solid ${Colors.theme.blue.light}`,
            borderInlineStart: `2px solid ${Colors.theme.blue.light}`,
            borderStartStartRadius: "0.37rem",
            borderEndStartRadius: "0.37rem",
            boxSizing: "border-box",
        }}>
            {/* <style>
                {`
                    @keyframes fadeInAndScaleArr {
                        0% {
                            opacity: 0;
                            transform: scaleX(0);
                        }
                        100% {
                            opacity: 1;
                            transform: scaleX(100%);
                            }
                    }
                    @keyframes fadeOnAndScaleArr {
                        0% {
                            opacity: 1;
                            transform: scaleX(100%);
                        }
                        100% {
                            opacity: 0;
                            transform: scaleX(0);
                        }
                    }
            `}
            </style> */}
            {paramsComponent.steps.map((steps, index, arr) => {
                if (steps.steps.length === 0) {
                    return (
                        <OneStep
                            callbackOnClick={function (): void {
                                paramsComponent.callbackOnClickOneStep(steps.keyStep);
                                paramsComponent.callbackOnClickOneGroup("1");
                            }}
                            keyOfOneStepThatSelected={getKeyOfOneStepThatSelected}
                            keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                            keyOfStepThatLastCompleted={getKeyOfStepThatLastCompleted}
                            {...steps}
                        />
                    )
                }
                else {
                    return (
                        <SteperGroup
                            {...steps}
                            callbackOnClickItem={(keySelected) => {
                                paramsComponent.callbackOnClickOneStep(keySelected);
                            }}
                            callbackOnClickGroup={(keySelected) => {
                                paramsComponent.callbackOnClickOneGroup(keySelected);
                            }}
                            keyOfOneStepThatSelected={getKeyOfOneStepThatSelected}
                            keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                            keyOfStepThatLastCompleted={getKeyOfStepThatLastCompleted}
                        />
                        // <div>
                        //     {steps.key}
                        // </div>
                    )

                }
            })
            }

        </div>
    )
};
export default SteperGuide;