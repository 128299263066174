import { Box, Grid, styled, useTheme } from "@mui/material";
import Colors from "../../../../../../../config/Colors";
import KeyTitleH6 from "../../../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import { useDispatch, useSelector } from "react-redux";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import ValueP from "../../../../../../../components/basic/Texts/Titles/ValueP/ValueP";
import { Person2Rounded } from "@mui/icons-material";
import { TrashIcon } from "../../../../../../../assets/icons/svg/TrashIcon";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../api/API";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { InterfaceRowRecommended } from "./InterfaceRowRecommended";
import { ImageSquare } from "../../../../../../../components/complex/imageSquare/ImageSquare";
import dayjs from "dayjs";
import DialogAcceptProcess from "../../../../../../../components/complex/Dialog/DialogAcceptProcess/DialogAcceptProcess";
import { useState } from "react";
const TagOfUser = styled("p")(({ theme }) => ({
    boxSizing: "border-box",
    fontSize: "0.96rem",
    paddingInline: "0.5rem",
    borderRadius: "500px",
    lineHeight: "1.44rem",
    marginInline: "0.4rem",
}));

function RowRecommended(paramsComponent: InterfaceRowRecommended) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getIsDialogDeleteShow, setIsDialogDeleteShow] = useState<boolean>(false);
    const callApiForDeleteRecommended = async (idRecommended: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recommendation.recommended.deleteRecommended;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idRecommended}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackReloadPage();
                dispatch(ShowAlert({ text: confComps.translate.removedCashAccount, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <Box sx={{
            width: "100%",
            border: `1px solid ${Colors.theme.blue.border}`,
            paddingInline: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
            paddingBlock: { xs: 0.5, sm: 1, md: 1, lg: 2, xl: 2 },
            boxSizing: "border-box",
            borderRadius: "0.8rem",
        }}>
            <Box sx={{
                paddingInlineEnd: { xs: 0, sm: 2, md: 4, lg: 5, xl: 6 },
            }}>
                <div style={{
                    display: "flex", flexDirection: "row", alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    boxSizing: "border-box",
                    flexWrap: "wrap",
                    gap: "1rem",

                }}>
                    <div style={{
                        display: "flex", flexDirection: "row", gap: "0.5rem",
                        alignItems: "center"
                    }}>
                        <ImageSquare width={"3.2rem"}
                            img={paramsComponent.recommender.profile.profile_pic && `${baseUrl}${paramsComponent.recommender.profile.profile_pic}`}
                            borderRadius="500px"
                            style={{ boxSizing: "border-box" }}
                            iconSvg={paramsComponent.recommender.profile.profile_pic ? undefined : Person2Rounded}
                            color={Colors.theme.blue.blue}
                            background={Colors.theme.white.PrimaryLight}
                        />
                        {paramsComponent.recommender.profile.first_name}
                        {" "}
                        {paramsComponent.recommender.profile.last_name}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", boxSizing: "border-box", gap: "0.3rem" }}>
                        {paramsComponent.recommender.user.is_customer &&
                            <TagOfUser
                                style={{
                                    color: Colors.theme.blue.dark,
                                    background: Colors.theme.orange.light
                                }}
                            >
                                {confComps.translate.customer}
                            </TagOfUser>
                        }
                        {paramsComponent.recommender.user.is_employee &&
                            <TagOfUser
                                style={{
                                    color: Colors.theme.blue.dark,
                                    background: Colors.theme.orange.light
                                }}>
                                {confComps.translate.employee}
                            </TagOfUser>
                        }
                        {paramsComponent.recommender.user.is_trusty &&
                            <TagOfUser
                                style={{
                                    color: Colors.theme.blue.Secondary,
                                    background: Colors.theme.blue.white,
                                }}
                            >
                                {confComps.translate.trusty}
                            </TagOfUser>
                        }

                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
                        <KeyTitleH6 title={confComps.translate.commissionRate} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.commission_rate} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
                        <KeyTitleH6 title={confComps.translate.date} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={dayjs.unix(paramsComponent.timestamp).format("YYYY/MM/DD")} />
                    </div>
                </div>


            </Box>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end", width: "100%", gap: "0.5rem" }}>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                    onClick={() => { setIsDialogDeleteShow(true); }}
                >
                    <TrashIcon style={{ width: "1.2rem", height: "1.1rem" }} />
                </div>

            </div>

            <DialogAcceptProcess
                greenButtonTitle={confComps.translate.yes}
                redButtonTitle={confComps.translate.no}
                isOpen={getIsDialogDeleteShow} title={"Are you Sure For Delete?"} description={""}
                callbackClickAccept={function (): void {
                    setIsDialogDeleteShow(false);
                    callApiForDeleteRecommended(paramsComponent.id)
                }}
                callbackClickReject={function (): void {
                    setIsDialogDeleteShow(false);
                }}
            >
                <div>
                    {`User: ${paramsComponent.recommender.profile.first_name} ${paramsComponent.recommender.profile.last_name}`}
                </div>
            </DialogAcceptProcess>


        </Box >
    );
}
export default RowRecommended;